footer {
  padding-bottom: to-rem(32);
  color: $color-black-10;
  background-color: #0000000f;

  .footer-top-navigation {
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: to-rem(12) 0 0;
      padding: 0 0 to-rem(12);
      list-style-type: none;

      li {
        a,
        span,
        strong {
          display: block;
          margin: to-rem(4) 0;
          padding: to-rem(4) to-rem(14);
          font-size: to-rem(13.6);
          line-height: to-rem(20.4);
          color: #7b7d7d;
        }

        a {
          text-decoration: none;
          border: none;
          transition: color 350ms ease;

          &:hover {
            color: #dd7b07;
            border: none;
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      ul {
        flex-direction: row;
        border-bottom: 1px solid #e0e0e0;
        box-shadow: 0 1px 0 #ffffff;

        li {

          &:first-of-type > * {
            margin-left: 0;
            padding-left: 0;
          }

          a,
          span,
          strong {
            margin: 0 to-rem(10);
            padding: to-rem(4) to-rem(8);
          }
        }
      }
    }
  }

  .node_wrapper {
    display: none;
    margin-top: to-rem(32);

    h4 {
      font-family: 'ITCFranklinGothicW02-Dm';
      font-size: to-rem(12);
      line-height: to-rem(19);
      font-weight: 600;
      color: #7b7d7d;
    }

    ul {
      display: flex;
      flex-direction: column;

      li {
        a,
        span,
        strong {
          font-size: to-rem(12);
          line-height: to-rem(19);
          color: #bbbbbb;
        }

        a {
          text-decoration: none;
          border: none;
          transition: color 350ms ease;

          &:hover {
            color: #dd7b07;
            border: none;
          }
        }

        strong {
          font-weight: 400;
          color: #dd7b07;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      display: block;
    }
  }
}
