/* -------------------------------------------------------------
//  Basics
// ---------------------------------------------------------- */

:root {
  // Root Font-Size
  font-size: $root-font-size + px;
}

body {
  background: url('../img/body_bg.png') top center;
  color: $color-black-90;
  font-family: $font-medium-font-family, Helvetica, Arial;
  font-size: to-rem(16);
  line-height: to-rem($font-medium-line-height);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  // Sticky footer
  min-height: 100vh;
  min-height: 100dvh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &.fix {
    overflow: hidden;
  }
}
