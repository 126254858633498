
.pagination {
  display: flex;
  justify-content: space-between;

  margin-top: to-rem(20);
  padding-top: to-rem(20);
  border-top: 1px solid white;
  box-shadow: 0 -1px 0 #e0e0e0;

  p {
    color: #7b7d7d;
  }

  ul {
    display: flex;
  }

  li {
    margin: 0 to-rem(4);

    strong {
      padding: 0 to-rem(4);
      font-family: 'ITCFranklinGothicW02-Dm';
    }

    a {
      padding: 0 to-rem(4);
      color: #7b7d7d;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-decoration-color: #7b7d7d;
      text-underline-offset: 2px;
      transition: all 350ms ease;
    }
  }
}
