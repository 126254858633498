
.collection-date {
  &__container {
    position: relative;
    margin-top: to-rem(75);
    padding: to-rem(40) to-rem(20) to-rem(20);
    background: #ffffffb3;
  }

  &__form {
    display: flex;
    padding-left: 0;
  }

  &__input {
    flex: 1;
    padding: to-rem(6) to-rem(20);
    border-radius: 30px;
    border: 1px solid #ededed;
    background: #ededed;
    transition: background 350ms ease;

    &::-webkit-calendar-picker-indicator {
      display: none !important;
    }

    &:focus {
      background: white;
    }
  }

  &__button {
    margin-left: to-rem(15);
    width: to-rem(34);
    height: to-rem(34);
    font-family: 'evbw';
    font-size: to-rem(21);
    line-height: to-rem(21);
    color: white;
    border-radius: to-rem(20);
    background: #bd010f;
  }

  &__image {
    position: absolute;
    bottom: to-rem(70);
    left: to-rem(20);
    width: to-rem(130);
    height: auto;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: inline-flex;
    color: #7b7d7d;

    &:before {
      content: "\e002";
      margin-right: to-rem(10);
      font-family: 'evbw';
    }

    &:not(:first-of-type) {
      margin-top: to-rem(7);
    }
  }

  &__link {
    transition: color 350ms ease;
  }

  &__result {

    .h2 {
      margin-top: 0;
    }

    &-list {
      color: #5c5c5c;
    }

    &-item {
      display: flex;
      padding: to-rem(14) 0 to-rem(14) to-rem(13);

      &:not(:first-of-type) {
        border-top: 1px solid white;
        box-shadow: 0 -1px 0 #e8e8e8;
      }
    }

    &-image {
      width: auto;
      height: to-rem(75);
    }

    &-content {
      padding-left: to-rem(20);
    }

    &-type {
      font-family: 'ITCFranklinGothicW02-Dm';
    }

    &-text {
      margin-top: to-rem(32);

      strong {
        font-family: 'ITCFranklinGothicW02-Dm';
      }
    }
  }

  @media only screen and (min-width: 768px) {
    &__container {
      margin-top: to-rem(45);
      padding-top: to-rem(20);
    }

    &__form {
      padding-left: to-rem(140);
    }

    &__image {
      bottom: to-rem(20);
    }
  }
}

.header-collage {
  $block: &;

  margin-top: to-rem(-56);
  height: 82px;

  &__container {
    position: relative;
    display: flex;
    align-items: stretch;
    height: 120px;
  }

  &__layer-back,
  &__layer-left,
  &__layer-right,
  &__layer-front {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &__layer-back {
    right: 0;
    left: 0;
    background: none;
    z-index: 10;
  }

  &__layer-left {
    right: 50%;
    left: 0;
    background: none;
    z-index: 20;
  }

  &__layer-right {
    right: 0;
    left: 50%;
    background: none;
    z-index: 20;
  }

  &__layer-front {
    right: 0;
    left: 0;
    background: url('../img/header/layer-front__small.png') top center no-repeat;
    background-size: contain;
    z-index: 30;
  }

  &--truck {
    #{$block}__layer-front {
      background: url('../img/header/layer-front__small--truck.png') top center no-repeat;
      background-size: contain;
    }
  }

  &--garbage {
    #{$block}__layer-front {
      background: url('../img/header/layer-front__small--garbage.png') top center no-repeat;
      background-size: contain;
    }
  }

  &--drainage {
    #{$block}__layer-front {
      background: url('../img/header/layer-front__small--drainage.png') top center no-repeat;
      background-size: contain;
    }
  }

  &--parking {
    #{$block}__layer-front {
      background: url('../img/header/layer-front__small--parking.png') top center no-repeat;
      background-size: contain;
    }
  }

  @media only screen and (min-width: 576px) {
    height: 125px;

    &__container {
      height: 182px;
    }
  }

  @media only screen and (min-width: 768px) {
    &__layer-back {
      right: 0;
      left: 0;
      background: url('../img/header/layer-back__medium.png') top center no-repeat;
      background-size: contain;
    }

    &__layer-left {
      right: 50%;
      left: 0;
      background: url('../img/header/layer-left__medium.png') top center no-repeat;
      background-size: contain;
    }

    &__layer-right {
      right: 0;
      left: 50%;
      background: url('../img/header/layer-right__medium.png') top center no-repeat;
      background-size: contain;
    }

    &__layer-front {
      right: 0;
      left: 0;
      background: url('../img/header/layer-front__large.png') top center no-repeat;
      background-size: contain;
    }

    &--truck {
      #{$block}__layer-front {
        background: url('../img/header/layer-front__large--truck.png') top center no-repeat;
        background-size: contain;

      }
    }
    &--garbage {
      #{$block}__layer-front {
        background: url('../img/header/layer-front__large--garbage.png') top center no-repeat;
        background-size: contain;

      }
    }
    &--drainage {
      #{$block}__layer-front {
        background: url('../img/header/layer-front__large--drainage.png') top center no-repeat;
        background-size: contain;

      }
    }
    &--parking {
      #{$block}__layer-front {
        background: url('../img/header/layer-front__large--parking.png') top center no-repeat;
        background-size: contain;

      }
    }
  }

  @media only screen and (min-width: 1200px) {

    &__layer-back {
      background: url('../img/header/layer-back__large.png') top center no-repeat;
      background-size: contain;
    }

    &__layer-left {
      background: url('../img/header/layer-left__large.png') top center no-repeat;
      background-size: contain;
    }

    &__layer-right {
      background: url('../img/header/layer-right__large.png') top center no-repeat;
      background-size: contain;
    }

    &__layer-front {
      background: url('../img/header/layer-front__large.png') top center no-repeat;
      background-size: contain;
    }
  }
}

.mod_search {

  .formbody {
    display: flex;
  }

  .widget-text {
    flex: 1;

    input {
      padding: to-rem(3) to-rem(7);
      width: 100%;
      border-radius: to-rem(6);
      border: 1px solid #e2e2e2;
      border-bottom-color: #cccccc;
      background: #fbfaf8;
    }
  }

  .widget-submit {
    button {
      margin-left: to-rem(4);
      padding: 0;
      font-family: 'evbw';
      font-size: to-rem(19);
      line-height: to-rem(30);
      color: #444444;
      background: none;
    }
  }

  .header {
    margin: to-rem(16) 0 0;
  }

  .info {
    color: #8e8e8e;
  }

  > div {
    &.first {
      margin-top: to-rem(20);
      padding-top: to-rem(20);
      border-top: 1px solid white;
      box-shadow: 0 -1px 0 #e0e0e0;
    }

    &:not(:first-of-type) {
      margin-top: to-rem(32);
    }

    a {
      text-decoration: none;

      &:hover {
        .url {
          text-decoration: underline;
          text-decoration-thickness: 1px;
          text-underline-offset: 2px;
        }
      }
    }

    h2 {
      margin: 0;
    }

    .url {
      margin: 0 0 to-rem(2);
      color: #44444480;
      word-break: break-all;
    }

    .context {
      color: #444444;
    }

    .highlight {
      padding: to-rem(1) to-rem(4);
      border-radius: to-rem(4);
      background-color: #f9c400;
    }
  }

  @media only screen and (min-width: 576px) {
    .widget-text {
      flex: initial;

      input {
        width: to-rem(320);
      }
    }
  }
}

.teaser-list {
  $block: &;

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 100%;

    &:not(:first-of-type) {
      margin-top: to-rem(20);
      padding-top: to-rem(20);
      border-top: 1px solid white;
      box-shadow: 0 -1px 0 #e0e0e0;
    }
  }

  &__image {
    img {
      width: auto;
    }
  }

  &--type-standard {
    #{$block}__headline {
      margin: 0 0 to-rem(6);
      font-family: 'ITCFranklinGothicW02-Dm';
      font-size: to-rem(16);
      line-height: to-rem(20);
      color: #5c5c5c;
    }

    #{$block}__text {
      font-size: to-rem(14);
      line-height: to-rem(21);
    }

    #{$block}__link {
      display: inline-block;
      margin-top: to-rem(14);
      padding: 0 to-rem(30);
      font-weight: bold;
      font-size: to-rem(14);
      line-height: to-rem(27);
      color: white;
      text-decoration: none;
      border-radius: to-rem(10);
      border: none;
      background: rgba(0, 0, 0, .2);
      transition: background 350ms ease;

      &:hover {
        background: #dd7b07;
      }
    }
  }

  &--type-bubble {
    position: relative;
    aspect-ratio: 1;
    background: url('../img/bubble.png') center no-repeat;
    background-size: contain;

    #{$block}__headline {
      position: absolute;
      bottom: 56%;
      margin: 0;
      width: 100%;
      font-family: 'ITCFranklinGothicW02-Dm';
      font-size: to-rem(48);
      line-height: to-rem(48);
      text-align: center;
    }

    #{$block}__text {
      position: absolute;
      top: 48%;
      padding: 0 to-rem(40);
      width: 100%;
      font-size: to-rem(14);
      color: #444444;
      text-align: center;
    }
  }

  &--type-collection {
    #{$block}__form {
      display: flex;
      margin: to-rem(10) 0 0;
    }

    #{$block}__input {
      flex: 1;
      padding: to-rem(7) to-rem(16) to-rem(6);
      font-size: to-rem(14);
      border-radius: 30px;
      border: 1px solid #ededed;
      background: #ededed;
      transition: background 350ms ease;

      &::-webkit-calendar-picker-indicator {
        display: none !important;
      }

      &:focus {
        background: white;
      }
    }

    #{$block}__submit {
      margin-left: to-rem(10);
      width: to-rem(34);
      height: to-rem(34);
      font-family: 'evbw';
      font-size: to-rem(21);
      line-height: to-rem(21);
      color: white;
      border-radius: to-rem(20);
      background: #bd010f;
    }
  }

  @media only screen and (min-width: 576px) {
    &__list {
      gap: to-rem(30);
    }
    &__item {
      width: calc(50% - 15px);

      &:not(:first-of-type) {
        margin: 0;
        padding: 0;
        border: none;
        box-shadow: none;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    &__item {
      width: calc(33.3333% - 20px);
    }
  }

  @media only screen and (min-width: 992px) {
    &__list {
      flex-direction: column;
      gap: 0;
    }

    &__item {
      width: 100%;

      &:not(:first-of-type) {
        margin-top: to-rem(20);
        padding-top: to-rem(20);
        border-top: 1px solid white;
        box-shadow: 0 -1px 0 #e0e0e0;
      }
    }
  }
}

