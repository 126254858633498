
.rsce-links {
  &__item {
    &:not(:first-of-type) {
      margin-top: to-rem(10);
    }
  }

  &__link {
    position: relative;
    display: block;
    padding: to-rem(14) to-rem(28) to-rem(14) to-rem(14);
    background: #0000000d;
    transition: background 350ms ease, color 350ms ease;

    &:hover {
      background: white;
    }

    &:after {
      content: "\e002";
      position: absolute;
      top: 50%;
      right: to-rem(8);
      font-family: 'evbw';
      color: #b2b2b2;
      transform: translateY(-50%);
    }
  }

  &__headline {
    margin: 0;
    color: #5c5c5c;
    transition: color 350ms ease;
  }

  &__text {
    margin: to-rem(3) 0 0;

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}