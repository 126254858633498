header {
  width: 100% !important;
  background-color: #ffffffb2;
  z-index: 10;

  .header-container {
    display: flex;
    justify-content: space-between;

    .space {
      flex: 1;
    }
  }

  .header-logo {
    position: relative;
    align-self: flex-start;
    margin-top: to-rem(23);
    z-index: 100;

    img {
      height: to-rem(60);
    }
  }

  .header-trigger {
    margin-top: to-rem(5);
    margin-right: to-rem(16);
    font-family: 'Iconfont';
    font-weight: 700;
    font-size: to-rem(18);
    line-height: to-rem(25);
    color: #8e8e8e;
    cursor: pointer;
    z-index: 100;
    transition: color 350ms ease;
  }

  .header-search {
    position: relative;
    z-index: 100;

    &.open {
      input {
        width: to-rem(140);
        border-color: #e2e2e2;
        background: #fbfaf8;
        transition: all 350ms ease;

        ::placeholder {
          color: #e2e2e2;
        }
      }
    }

    .formbody {
      display: flex;
      margin-top: to-rem(5);
    }

    input {
      padding: 0 to-rem(7);
      width: to-rem(30);
      color: #8e8e8e;
      border-color: transparent;
      border-radius: to-rem(20);
      background: none;

      &::placeholder {
        color: transparent;
      }
    }

    button {
      margin-left: to-rem(-24);
      padding: 0;
      font-family: 'evbw';
      font-size: to-rem(16);
      line-height: to-rem(25);
      color: #8e8e8e;
      background: none !important;
      transition: color 350ms ease;
    }
  }

  .top-navigation {
    display: none;
    margin-top: to-rem(4);

    ul {
      display: flex;
    }

    li {
      margin-right: to-rem(26);
    }

    a,
    strong {
      font-size: to-rem(14);
      line-height: to-rem(26);
      color: #8e8e8e;
    }
  }

  @media only screen and (min-width: 768px) {
    .top-navigation {
      display: block;
    }

    .header-logo {
      img {
        height: to-rem(80);
      }
    }

    .header-trigger {
      display: none;
    }

    .header-search {
      input {
        width: to-rem(140);
        border-color: #e2e2e2;
        background: #fbfaf8;

        &::placeholder {
          color: #e2e2e2;
        }
      }
    }
  }
}

#below-header {
  display: none;
  margin-top: to-rem(57);

  .main-navigation {
    .level_1 {
      display: flex;
      justify-content: space-between;
      padding-bottom: to-rem(20);
      border-bottom: 1px solid #e0e0e0;
      -webkit-box-shadow: 0 1px 0 white;

      li {
        border-left: 1px solid white;
        box-shadow: -1px 0 0 #e0e0e0;

        &.color-red {
          > a:hover,
          > strong {
            color: var(--accent-red);
          }
        }

        &.color-green {
          > a:hover,
          > strong {
            color: var(--accent-green);
          }
        }

        &.color-blue {
          > a:hover,
          > strong {
            color: var(--accent-blue);
          }
        }

        &.color-purple {
          > a:hover,
          > strong {
            color: var(--accent-purple);
          }
        }

        &.color-orange {
          > a:hover,
          > strong {
            color: var(--accent-orange);
          }
        }

        > a,
        > strong {
          position: relative;
          display: block;
          padding: to-rem(24) to-rem(16) to-rem(6);
          font-family: 'ITCFranklinGothicW02-Dm';
          font-weight: 700;
          font-size: clamp-calc-minmax(12px, 18px);
          line-height: clamp-calc-minmax(20px, 27px);
          color: #707070;
          text-decoration: none;
          border: none;

          &:before {
            position: absolute;
            top: 0;
            content: "\e001";
            font-family: 'evbw';
            transition: top 350ms ease;
          }

          &:hover:before {
            top: to-rem(2);
          }
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    display: block;
  }
}
