
:root {
  --accent-red: #bd010f;
  --accent-green: #6c9712;
  --accent-blue: #0a3f73;
  --accent-purple: #602165;
  --accent-orange: #dd7b07;
}

body {
  &.color-red {
    --color: var(--accent-red);
  }

  &.color-green {
    --color: var(--accent-green);
  }

  &.color-blue {
    --color: var(--accent-blue);
  }

  &.color-purple {
    --color: var(--accent-purple);
  }

  &.color-orange {
    --color: var(--accent-orange);
  }

  h1,
  h2,
  h3,
  h4,
  a:not([class]):hover,
  footer .footer-top-navigation ul li a:hover,
  footer .node_wrapper ul li a:hover,
  footer .node_wrapper ul li strong,
  #below-header .main-navigation .level_1 li > a.trail,
  #below-header .main-navigation .level_1 li > strong.active,
  .left-navigation .level_1 a.trail,
  .left-navigation .level_1 strong.active,
  .left-navigation .level_1 a:hover,
  .top-navigation a:hover,
  .top-navigation strong.active,
  .rsce-links__link:hover,
  .rsce-links__link:hover .rsce-links__headline,
  .header-search button:hover,
  .pagination li strong.active,
  .pagination li a:hover,
  .collection-date__link:hover.top-navigation,
  .header-trigger.active,
  .header-trigger:hover,
  .teaser-list--type-bubble .teaser-list__headline {
    color: var(--color);
  }

  a:not([class]):hover,
  .pagination li a:hover {
    text-decoration-color: var(--color);
  }

  .widget-submit button:hover,
  .teaser-list--type-standard .teaser-list__link:hover,
  .ce_hyperlink a:hover {
    background-color: var(--color);
  }

}