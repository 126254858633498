.logo {
  display: block;
  margin: 0;
  width: 171px;

  img {
    margin-bottom: 0;
  }
}
.logo--footer {
  width: 170px;
}
@media only screen and (min-width: 768px) {
  .logo {
    width: 195px;
  }
}
@media only screen and (min-width: 1200px) {
  .logo {
    width: 229px;
  }
}
