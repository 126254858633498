
.ce_hyperlink {
  a {
    overflow: hidden;
    display: inline-block;
    padding: to-rem(2) to-rem(30);
    line-height: to-rem(25);
    font-family: 'ITCFranklinGothicW02-Dm';
    color: white;
    border-radius: 30px;
    border: none;
    background: #707070;
    transition: background 350ms ease;
  }
}

.ce_table {
  margin-bottom: 1.3125rem;
  th,
  td {
    padding: to-rem(4) to-rem(6) to-rem(4) 0;
  }
}

.ce_text {
  font-size: to-rem(14);
  line-height: to-rem(21);
  color: #444444;

  strong {
    font-family: 'ITCFranklinGothicW02-Dm';
  }

  ul,
  ol {
    margin-bottom: to-rem(24);
    padding-left: to-rem(20);

    li {
      margin-bottom: to-rem(7);
    }

    p {
      margin: 0;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
}

.ce_image {
  margin-bottom: to-rem(21);

  img {
    width: auto;
  }

  .caption {
    font-size: .75rem;
    line-height: 1.125rem;
    margin: 0.25rem 0 1.3125rem;
  }
}