/* -------------------------------------------------------------
//  Checkbox
// -----------------------------------------------------------*/

input[type="checkbox"] {
  appearance: none;
  // For iOS < 15 to remove gradient background
  background-color: #fff;
  // Not removed via appearance
  margin: 0 to-rem(calc($font-medium-font-size / 2)) 0 0;

  // Styling
  font: inherit;
  color: currentColor;
  width: to-rem($font-medium-line-height);
  height: to-rem($font-medium-line-height);
  border: 1px solid $color-black-60;
  border-radius: 2px;
  transform: translateY(-0.075em); // prefered to flexbox due to multiline text could happen
  
  display: inline-grid;
  place-content: center;

  &::before {
    content: "";
    width: 0.75em;
    height: 0.75em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $color-primary;
  }

  &:checked:before {
    transform: scale(1);
  }
}