/* -------------------------------------------------------------
//  Navigation
// -----------------------------------------------------------*/

.left-navigation {
  .level_1 {

    > li {
      padding: to-rem(4) 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid #e0e0e0;
        box-shadow: 0 1px 0 white
      }
    }

    a,
    strong {
      display: inline-block;
      padding: to-rem(6) 0;
      font-family: 'ITCFranklinGothicW02-Dm';
      font-size: clamp-calc-minmax(15px, 15px);
      line-height: clamp-calc-minmax(18px, 18px);
      color: #444444;
      text-decoration: none;
      border: none;
      transition: color 350ms ease;
    }

  }

  .level_2 {
    padding-left: to-rem(14);

    a,
    strong {
      display: inline-block;
      margin: to-rem(4) 0;
      padding: to-rem(4) 0;
      font-size: clamp-calc-minmax(14px, 14px);
      line-height: clamp-calc-minmax(17px, 17px);
      color: #8a8a8a;
    }
  }

  .level_3 {
    padding-left: to-rem(14);
  }

  .level_4 {
    padding-left: to-rem(14);
  }

  .level_5 {
    padding-left: to-rem(14);
  }
}

.mobile-navigation {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: opacity 250ms linear, max-height 350ms linear 250ms;

  &.active {
    max-height: 320px;
    opacity: 1;
    transition: max-height 350ms linear, opacity 250ms linear 350ms;
  }

  ul {
    margin: 0 0 clamp-calc-minmax(32px, 48px);

    li {
      position: relative;
      margin: 0;
      padding: to-rem(9) 0;
      border-top: 1px solid #e0e0e0;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 0;
        border-top: 1px solid white;
      }

      &:last-of-type {
        border-bottom: 1px solid white;

        &:after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 0;
          border-bottom: 1px solid #e0e0e0;
        }
      }

      &.color-red {
        a:hover,
        strong {
          color: var(--accent-red);
        }
      }

      &.color-green {
        a:hover,
        strong {
          color: var(--accent-green);
        }
      }

      &.color-blue {
        a:hover,
        strong {
          color: var(--accent-blue);
        }
      }

      &.color-purple {
        a:hover,
        strong {
          color: var(--accent-purple);
        }
      }

      &.color-orange {
        a:hover,
        strong {
          color: var(--accent-orange);
        }
      }

      a,
      strong {
        position: relative;
        display: block;
        font-family: 'ITCFranklinGothicW02-Dm';
        font-size: to-rem(18);
        line-height: to-rem(27);
        color: #707070;
        transition: color 350ms ease;

        &:after {
          content: "\e908";
          position: absolute;
          top: 0;
          right: 0;
          font-family: 'Iconfont';
          font-weight: 700;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.nav {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &__container {
    // Mobile First Dropdown Navigation
    background-color: $color-white;

    visibility: hidden;
    opacity: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    inset: 0 0 0 0;
    position: fixed;

    transition: all 500ms ease-in-out;

    // Desktop Viewport
    @media (min-width: 1200px) {
      visibility: visible;
      opacity: 1;

      background: none;

      position: relative;
      top: auto !important;

      display: flex;
      flex-direction: row;
    }
    //navbar__container toggle
    &.show {
      visibility: visible;
      opacity: 1;
      overflow: auto;
    }
  }

  // navbar__list ul
  &__list {
    position: relative;

    // Desktop Viewport
    @media (min-width: 1200px) {

    }

    &.active {

    }
  }

  // navbar__list li
  &__item {
    text-align: center;
    // Desktop Viewport
    @media only screen and (min-width: 1200px) {
      display: inline;
    }
  }

  // navbar__list li a
  &__link {
    color: $color-black-90 !important;
    @include font-fallbacks($font-button-font-family);
    font-size: to-rem($font-button-font-size);
    font-weight: $font-button-font-weight;
    line-height: to-rem($font-button-line-height);

    display: block;
    text-decoration: none;
    margin: 0;
    padding: 15px;
    position: relative;

    // Desktop Viewport
    @media only screen and (min-width: 1200px) {
      display: inline-block;
      padding: 0;
      margin: 0 7px 0 10px;
    }

    &:hover, &:focus {

      // Desktop Viewport
      @media only screen and (min-width: 1200px) {
        color: $color-primary !important;
      }
      color: $color-primary !important;
    }

    &.active {

      // Desktop Viewport
      @media only screen and (min-width: 1200px) {
        // Desktop Viewport
        color: $color-primary !important;
      }
      color: $color-primary !important;
    }
  }
}

// Burger Iconfont


// Burger Icon
.nav__toggle-icon {
  cursor: pointer;
  height: 20px;
  position: relative;
  width: 20px;
  z-index: 15;
  transition: top $transition--slow;

  & > i {
    font-size: to-rem($font-medium-line-height);
  }
}

/* Burgericon */
.burger-line {
  background-color: $color-black-90;
  display: block;
  height: 2px;
  position: absolute;
  width: 20px;
}

.burger-line--top {
  left: 0;
  top: 4px;
}

.burger-line--middle {
  left: 0;
  top: 11px;
}

.burger-line--bottom {
  left: 0px;
  top: 18px;
}

.nav__toggle-icon.toggle .burger-line--top {
  animation: line-top-animation 400ms 0s 1 forwards;
}

@keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}

.nav__toggle-icon.toggle .burger-line--bottom {
  animation: line-bottom-animation 400ms 0s 1 forwards;
}


@keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}

.nav__toggle-icon.toggle .burger-line--middle {
  animation: line-middle-animation 400ms 0s 1 forwards;
}

@-webkit-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}

@keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}

.nav__toggle-icon .burger-line--top {
  animation: line-top-animation-back 400ms 0s 1 forwards;
}

@keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}

.nav__toggle-icon .burger-line--bottom {
  animation: line-bottom-animation-back 400ms 0s 1 forwards;
}

@keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}

.nav__toggle-icon .burger-line--middle {
  animation: line-center-animation-back 400ms 0s 1 forwards;
}

@keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}

