/* -------------------------------------------------------------
//  Fonts
// -----------------------------------------------------------*/

@mixin font-fallbacks($font) {
  font-family: $font, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}

$root-font-size: 16;

/* fonts */
@font-face {
  font-family: "ITCFranklinGothicW02-Dm";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/ITCFranklinGothicW02-Dm.woff") format("woff");
}
@font-face {
  font-family: "ITCFranklinGothicW02-Bk";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/ITCFranklinGothicW02-Bk.woff") format("woff");
}

/* icons */
@font-face {
  font-family: "evbw";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/evbw.woff") format("woff");
}

