/* -------------------------------------------------------------
//  Elevations
// ---------------------------------------------------------- */

@mixin elevation($offset-01-x, $offset-01-y, $blur-01, $color-01, $offset-02-x, $offset-02-y, $blur-02, $color-02) {
  box-shadow:
    ($offset-01-x + px, $offset-01-y + px, $blur-01 + px, $color-01),
    ($offset-02-x + px, $offset-02-y + px, $blur-02 + px, $color-02);
}

@mixin elevation-light-01() {
  @include elevation($effect-elevation-light-01-0-offset-x, $effect-elevation-light-01-0-offset-y, $effect-elevation-light-01-0-radius, $effect-elevation-light-01-0-color, $effect-elevation-light-01-1-offset-x, $effect-elevation-light-01-1-offset-y, $effect-elevation-light-01-1-radius, $effect-elevation-light-01-1-color);
}

@mixin elevation-light-02() {
  @include elevation($effect-elevation-light-02-0-offset-x, $effect-elevation-light-02-0-offset-y, $effect-elevation-light-02-0-radius, $effect-elevation-light-02-0-color, $effect-elevation-light-02-1-offset-x, $effect-elevation-light-02-1-offset-y, $effect-elevation-light-02-1-radius, $effect-elevation-light-02-1-color);
}

@mixin elevation-light-03() {
  @include elevation($effect-elevation-light-03-0-offset-x, $effect-elevation-light-03-0-offset-y, $effect-elevation-light-03-0-radius, $effect-elevation-light-03-0-color, $effect-elevation-light-03-1-offset-x, $effect-elevation-light-03-1-offset-y, $effect-elevation-light-03-1-radius, $effect-elevation-light-03-1-color);
}

@mixin elevation-light-04() {
  @include elevation($effect-elevation-light-04-0-offset-x, $effect-elevation-light-04-0-offset-y, $effect-elevation-light-04-0-radius, $effect-elevation-light-04-0-color, $effect-elevation-light-04-1-offset-x, $effect-elevation-light-04-1-offset-y, $effect-elevation-light-04-1-radius, $effect-elevation-light-04-1-color);
}

@mixin elevation-light-05() {
  @include elevation($effect-elevation-light-05-0-offset-x, $effect-elevation-light-05-0-offset-y, $effect-elevation-light-05-0-radius, $effect-elevation-light-05-0-color, $effect-elevation-light-05-1-offset-x, $effect-elevation-light-05-1-offset-y, $effect-elevation-light-05-1-radius, $effect-elevation-light-05-1-color);
}

@mixin elevation-light-06() {
  @include elevation($effect-elevation-light-06-0-offset-x, $effect-elevation-light-06-0-offset-y, $effect-elevation-light-06-0-radius, $effect-elevation-light-06-0-color, $effect-elevation-light-06-1-offset-x, $effect-elevation-light-06-1-offset-y, $effect-elevation-light-06-1-radius, $effect-elevation-light-06-1-color);
}

@mixin elevation-dark-01() {
  @include elevation($effect-elevation-dark-01-0-offset-x, $effect-elevation-dark-01-0-offset-y, $effect-elevation-dark-01-0-radius, $effect-elevation-dark-01-0-color, $effect-elevation-dark-01-1-offset-x, $effect-elevation-dark-01-1-offset-y, $effect-elevation-dark-01-1-radius, $effect-elevation-dark-01-1-color);
}

@mixin elevation-dark-02() {
  @include elevation($effect-elevation-dark-02-0-offset-x, $effect-elevation-dark-02-0-offset-y, $effect-elevation-dark-02-0-radius, $effect-elevation-dark-02-0-color, $effect-elevation-dark-02-1-offset-x, $effect-elevation-dark-02-1-offset-y, $effect-elevation-dark-02-1-radius, $effect-elevation-dark-02-1-color);
}

@mixin elevation-dark-03() {
  @include elevation($effect-elevation-dark-03-0-offset-x, $effect-elevation-dark-03-0-offset-y, $effect-elevation-dark-03-0-radius, $effect-elevation-dark-03-0-color, $effect-elevation-dark-03-1-offset-x, $effect-elevation-dark-03-1-offset-y, $effect-elevation-dark-03-1-radius, $effect-elevation-dark-03-1-color);
}

@mixin elevation-dark-04() {
  @include elevation($effect-elevation-dark-04-0-offset-x, $effect-elevation-dark-04-0-offset-y, $effect-elevation-dark-04-0-radius, $effect-elevation-dark-04-0-color, $effect-elevation-dark-04-1-offset-x, $effect-elevation-dark-04-1-offset-y, $effect-elevation-dark-04-1-radius, $effect-elevation-dark-04-1-color);
}

@mixin elevation-dark-05() {
  @include elevation($effect-elevation-dark-05-0-offset-x, $effect-elevation-dark-05-0-offset-y, $effect-elevation-dark-05-0-radius, $effect-elevation-dark-05-0-color, $effect-elevation-dark-05-1-offset-x, $effect-elevation-dark-05-1-offset-y, $effect-elevation-dark-05-1-radius, $effect-elevation-dark-05-1-color);
}

@mixin elevation-dark-06() {
  @include elevation($effect-elevation-dark-06-0-offset-x, $effect-elevation-dark-06-0-offset-y, $effect-elevation-dark-06-0-radius, $effect-elevation-dark-06-0-color, $effect-elevation-dark-06-1-offset-x, $effect-elevation-dark-06-1-offset-y, $effect-elevation-dark-06-1-radius, $effect-elevation-dark-06-1-color);
}

// Light Elevations

.elevation-light-01 {
  @include elevation($effect-elevation-light-01-0-offset-x, $effect-elevation-light-01-0-offset-y, $effect-elevation-light-01-0-radius, $effect-elevation-light-01-0-color, $effect-elevation-light-01-1-offset-x, $effect-elevation-light-01-1-offset-y, $effect-elevation-light-01-1-radius, $effect-elevation-light-01-1-color);
}

.elevation-light-02 {
  @include elevation($effect-elevation-light-02-0-offset-x, $effect-elevation-light-02-0-offset-y, $effect-elevation-light-02-0-radius, $effect-elevation-light-02-0-color, $effect-elevation-light-02-1-offset-x, $effect-elevation-light-02-1-offset-y, $effect-elevation-light-02-1-radius, $effect-elevation-light-02-1-color);
}

.elevation-light-03 {
  @include elevation($effect-elevation-light-03-0-offset-x, $effect-elevation-light-03-0-offset-y, $effect-elevation-light-03-0-radius, $effect-elevation-light-03-0-color, $effect-elevation-light-03-1-offset-x, $effect-elevation-light-03-1-offset-y, $effect-elevation-light-03-1-radius, $effect-elevation-light-03-1-color);
}

.elevation-light-04 {
  @include elevation($effect-elevation-light-04-0-offset-x, $effect-elevation-light-04-0-offset-y, $effect-elevation-light-04-0-radius, $effect-elevation-light-04-0-color, $effect-elevation-light-04-1-offset-x, $effect-elevation-light-04-1-offset-y, $effect-elevation-light-04-1-radius, $effect-elevation-light-04-1-color);
}

.elevation-light-05 {
  @include elevation($effect-elevation-light-05-0-offset-x, $effect-elevation-light-05-0-offset-y, $effect-elevation-light-05-0-radius, $effect-elevation-light-05-0-color, $effect-elevation-light-05-1-offset-x, $effect-elevation-light-05-1-offset-y, $effect-elevation-light-05-1-radius, $effect-elevation-light-05-1-color);
}

.elevation-light-06 {
  @include elevation($effect-elevation-light-06-0-offset-x, $effect-elevation-light-06-0-offset-y, $effect-elevation-light-06-0-radius, $effect-elevation-light-06-0-color, $effect-elevation-light-06-1-offset-x, $effect-elevation-light-06-1-offset-y, $effect-elevation-light-06-1-radius, $effect-elevation-light-06-1-color);
}

// Dark Elevations

.elevation-dark-01 {
  @include elevation($effect-elevation-dark-01-0-offset-x, $effect-elevation-dark-01-0-offset-y, $effect-elevation-dark-01-0-radius, $effect-elevation-dark-01-0-color, $effect-elevation-dark-01-1-offset-x, $effect-elevation-dark-01-1-offset-y, $effect-elevation-dark-01-1-radius, $effect-elevation-dark-01-1-color);
}

.elevation-dark-02 {
  @include elevation($effect-elevation-dark-02-0-offset-x, $effect-elevation-dark-02-0-offset-y, $effect-elevation-dark-02-0-radius, $effect-elevation-dark-02-0-color, $effect-elevation-dark-02-1-offset-x, $effect-elevation-dark-02-1-offset-y, $effect-elevation-dark-02-1-radius, $effect-elevation-dark-02-1-color);
}

.elevation-dark-03 {
  @include elevation($effect-elevation-dark-03-0-offset-x, $effect-elevation-dark-03-0-offset-y, $effect-elevation-dark-03-0-radius, $effect-elevation-dark-03-0-color, $effect-elevation-dark-03-1-offset-x, $effect-elevation-dark-03-1-offset-y, $effect-elevation-dark-03-1-radius, $effect-elevation-dark-03-1-color);
}

.elevation-dark-04 {
  @include elevation($effect-elevation-dark-04-0-offset-x, $effect-elevation-dark-04-0-offset-y, $effect-elevation-dark-04-0-radius, $effect-elevation-dark-04-0-color, $effect-elevation-dark-04-1-offset-x, $effect-elevation-dark-04-1-offset-y, $effect-elevation-dark-04-1-radius, $effect-elevation-dark-04-1-color);
}

.elevation-dark-01 {
  @include elevation($effect-elevation-dark-01-0-offset-x, $effect-elevation-dark-01-0-offset-y, $effect-elevation-dark-01-0-radius, $effect-elevation-dark-01-0-color, $effect-elevation-dark-01-1-offset-x, $effect-elevation-dark-01-1-offset-y, $effect-elevation-dark-01-1-radius, $effect-elevation-dark-01-1-color);
}

.elevation-dark-06 {
  @include elevation($effect-elevation-dark-06-0-offset-x, $effect-elevation-dark-06-0-offset-y, $effect-elevation-dark-06-0-radius, $effect-elevation-dark-06-0-color, $effect-elevation-dark-06-1-offset-x, $effect-elevation-dark-06-1-offset-y, $effect-elevation-dark-06-1-radius, $effect-elevation-dark-06-1-color);
}