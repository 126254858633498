::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-black-60;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $color-black-60;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $color-black-60;
}

form {
  &.grid-form {
    .formbody {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: to-rem(7);
    }
  }

  h2 {
    margin-bottom: 0;
    color: #5c5c5c;
  }

  .columns-1.as-row {
    display: flex;
    align-items: center;
    grid-column: 1/-1;

    .widget-text {
      width: 20%;
    }

    .widget-explanation {
      p {
        margin: 0;
      }
    }
  }
}

.widget {
  position: relative;

  &.hide {
    display: none !important;
  }

  &.columns-1 {
    grid-column: 1/-1;
  }

  &.columns-2 {
    grid-column: 1/3;
  }

  &.width-20 {
    input {
      max-width: 20%;
    }
  }

  &.border-top {
    margin-top: to-rem(10);
    padding-top: to-rem(10);
    border-top: 1px solid white;
    box-shadow: 0 -1px 0 #e0e0e0;
  }

  &.border-bottom {
    margin-bottom: to-rem(10);
    padding-bottom: to-rem(10);
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0 1px 0 white;

    p:last-of-type {
      margin: 0;
    }
  }

  &.border-top.border-bottom {
    box-shadow: 0 1px 0 white, 0 -1px 0 #e0e0e0;
  }

  &.align-reverse {
    display: flex;
    align-items: center;

    label {
      margin-left: to-rem(8);
    }
  }

  &.label-overlay {
    ::placeholder {
      color: transparent;
    }
  }

  label {
    &:after {
      content: none;
    }

    &.label-overlay {
      position: absolute;
      top: 0;
      left: to-rem(8);
      line-height: to-rem(28);
      color: #7b7d7d;
      cursor: text;

      .mandatory {
        padding-left: to-rem(4);
        font-family: 'ITCFranklinGothicW02-Dm';
        color: #bd010f;
      }
    }
  }

  &-explanation {
    p {
      &:first-of-type {
        margin-top: to-rem(8);
      }

      &:last-of-type {
        margin-bottom: to-rem(-6);
      }
    }
  }

  &-text input,
  &-textarea textarea {
    margin: 0;
    padding: to-rem(3) to-rem(8);
    width: 100%;
    color: #444444;
    border-radius: to-rem(6);
    border: 1px solid #e2e2e2;
    background: #fbfaf8;

    &.label-overlay {
      &:focus + label,
      &:not(:placeholder-shown) + label {
        display: none;
      }
    }
  }

  &-textarea {
    margin-top: to-rem(8);
  }

  &-checkbox,
  &-textarea {
    grid-column: 1 / -1;
  }

  &-checkbox {
    margin: to-rem(16) 0;
    .block {
      display: flex;

      input {
        flex-shrink: 0;
      }
    }

    .checkbox_container {
      display: flex;
      flex-direction: column;
      gap: to-rem(8);

      span {
        display: flex;
      }
    }
  }

  &-submit {
    button {
      overflow: hidden;
      display: inline-block;
      padding: to-rem(2) to-rem(30);
      line-height: to-rem(25);
      font-family: 'ITCFranklinGothicW02-Dm';
      color: white;
      border-radius: 30px;
      border: none;
      background: #707070;
      transition: background 350ms ease;
    }
  }
}

@media only screen and (min-width: 768px) {
  .contact__form {
    .formbody {
      grid-template-columns: 1fr 1fr;
    }
  }
}