/* -------------------------------------------------------------
//  List
// -----------------------------------------------------------*/
.list {
  margin: 0;
  margin-bottom: to-rem($font-medium-line-height);
  padding: 0;

  &__item {
    font-size: to-rem($font-medium-font-size);
    line-height: to-rem($font-medium-line-height);
    margin: 0;
    margin-left: to-rem($font-medium-font-size);
    margin-bottom: to-rem($font-medium-line-height);
    padding: 0;
  }
}
