@media only print {
  body {
    #header .top-navigation,
    #header .ce_form,
    #header .header-collage,
    #below-header,
    .collection-date__headline,
    .collection-date__container,
    #left,
    #right,
    #footer,{
      display: none !important;
    }

    .header-container {
      position: relative;
      flex-direction: row-reverse;
    }

    #main {
      margin-top: -50px;
      border: none !important;
      box-shadow: none !important;
    }

    * {
      color: black !important;
    }
  }
}