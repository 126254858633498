p {
  font-size: to-rem($font-medium-font-size);
  line-height: to-rem($font-medium-line-height);
  margin-bottom: to-rem($font-medium-line-height);
}

.font-size-xxlarge {
  font-size: to-rem($font-xxlarge-font-size);
  line-height: to-rem($font-xxlarge-line-height);
  margin-bottom: to-rem($font-xxlarge-line-height);
}

.font-size-xlarge {
  font-size: to-rem($font-xlarge-font-size);
  line-height: to-rem($font-xlarge-line-height);
  margin-bottom: to-rem($font-xlarge-line-height);
}

.font-size-large {
  font-size: to-rem($font-large-font-size);
  line-height: to-rem($font-large-line-height);
  margin-bottom: to-rem($font-large-line-height);
}

small, .font-size-small {
  font-size: to-rem($font-small-font-size);
  line-height: to-rem($font-small-line-height);
  margin-bottom: to-rem($font-small-line-height);
}

.font-size-xsmall {
  font-size: to-rem($font-xsmall-font-size);
  line-height: to-rem($font-xsmall-line-height);
  margin-bottom: to-rem($font-xsmall-line-height);
}

.font-size-xxsmall {
  font-size: to-rem($font-xxsmall-font-size);
  line-height: to-rem($font-xxsmall-line-height);
  margin-bottom: to-rem($font-xxsmall-line-height);
}

strong {
  font-weight: 700;
}

.col-12 > p {
  margin-top: 0; // resets margin of first paragraph
}