/* -------------------------------------------------------------
//  Animation & Timing
// ---------------------------------------------------------- */
/* -------------------------------------------------------------
//  Elevations
// ---------------------------------------------------------- */
.elevation-light-01 {
  box-shadow: 0px, 0.5px, 2px, rgba(96, 97, 112, 0.16), 0px, 0px, 1px, rgba(40, 41, 61, 0.08);
}

.elevation-light-02 {
  box-shadow: 0px, 2px, 4px, rgba(96, 97, 112, 0.16), 0px, 0px, 1px, rgba(40, 41, 61, 0.04);
}

.elevation-light-03 {
  box-shadow: 0px, 4px, 8px, rgba(96, 97, 112, 0.16), 0px, 0px, 2px, rgba(40, 41, 61, 0.04);
}

.elevation-light-04 {
  box-shadow: 0px, 8px, 16px, rgba(96, 97, 112, 0.16), 0px, 2px, 4px, rgba(40, 41, 61, 0.04);
}

.elevation-light-05 {
  box-shadow: 0px, 16px, 24px, rgba(96, 97, 112, 0.16), 0px, 2px, 8px, rgba(40, 41, 61, 0.04);
}

.elevation-light-06 {
  box-shadow: 0px, 20px, 32px, rgba(96, 97, 112, 0.24), 0px, 2px, 8px, rgba(40, 41, 61, 0.08);
}

.elevation-dark-01 {
  box-shadow: 0px, 1px, 2px, rgba(0, 0, 0, 0.32), 0px, 0px, 1px, rgba(40, 41, 61, 0.08);
}

.elevation-dark-02 {
  box-shadow: 0px, 2px, 4px, rgba(0, 0, 0, 0.32), 0px, 0px, 1px, rgba(0, 0, 0, 0.04);
}

.elevation-dark-03 {
  box-shadow: 0px, 4px, 8px, rgba(0, 0, 0, 0.32), 0px, 0px, 2px, rgba(0, 0, 0, 0.04);
}

.elevation-dark-04 {
  box-shadow: 0px, 8px, 16px, rgba(0, 0, 0, 0.32), 0px, 2px, 4px, rgba(0, 0, 0, 0.04);
}

.elevation-dark-01 {
  box-shadow: 0px, 1px, 2px, rgba(0, 0, 0, 0.32), 0px, 0px, 1px, rgba(40, 41, 61, 0.08);
}

.elevation-dark-06 {
  box-shadow: 0px, 20px, 32px, rgba(0, 0, 0, 0.32), 0px, 2px, 8px, rgba(0, 0, 0, 0.08);
}

/* -------------------------------------------------------------
//  Fonts
// -----------------------------------------------------------*/
/* fonts */
@font-face {
  font-family: "ITCFranklinGothicW02-Dm";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/ITCFranklinGothicW02-Dm.woff") format("woff");
}
@font-face {
  font-family: "ITCFranklinGothicW02-Bk";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/ITCFranklinGothicW02-Bk.woff") format("woff");
}
/* icons */
@font-face {
  font-family: "evbw";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/evbw.woff") format("woff");
}
/* -------------------------------------------------------------
//  Iconfont
// -----------------------------------------------------------*/
@font-face {
  font-family: "iconfont";
  src: url("../fonts/iconfont.ttf") format("truetype"), url("../fonts/iconfont.woff") format("woff");
  font-display: block;
  font-style: normal;
  font-weight: normal;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iconfont" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e900";
}

.icon-arrow-left:before {
  content: "\e901";
}

.icon-arrow-right:before {
  content: "\e902";
}

.icon-arrow-up:before {
  content: "\e903";
}

.icon-calendar:before {
  content: "\e904";
}

.icon-chat:before {
  content: "\e905";
}

.icon-chevron-down:before {
  content: "\e906";
}

.icon-chevron-left:before {
  content: "\e907";
}

.icon-chevron-right:before {
  content: "\e908";
}

.icon-chevron-up:before {
  content: "\e909";
}

.icon-close:before {
  content: "\e90a";
}

.icon-delete:before {
  content: "\e90b";
}

.icon-document:before {
  content: "\e90c";
}

.icon-download:before {
  content: "\e90d";
}

.icon-edit:before {
  content: "\e90e";
}

.icon-envelope:before {
  content: "\e90f";
}

.icon-external-link:before {
  content: "\e910";
}

.icon-facebook:before {
  content: "\e911";
}

.icon-filter-1:before {
  content: "\e912";
}

.icon-filter:before {
  content: "\e913";
}

.icon-google:before {
  content: "\e914";
}

.icon-hide:before {
  content: "\e915";
}

.icon-home:before {
  content: "\e916";
}

.icon-image:before {
  content: "\e917";
}

.icon-instagram:before {
  content: "\e918";
}

.icon-like:before {
  content: "\e919";
}

.icon-linkedin:before {
  content: "\e91a";
}

.icon-location-pin:before {
  content: "\e91b";
}

.icon-lock:before {
  content: "\e91c";
}

.icon-menu:before {
  content: "\e91d";
}

.icon-minus:before {
  content: "\e91e";
}

.icon-phone:before {
  content: "\e91f";
}

.icon-plus:before {
  content: "\e920";
}

.icon-profile:before {
  content: "\e921";
}

.icon-search:before {
  content: "\e922";
}

.icon-settings:before {
  content: "\e923";
}

.icon-shopping-cart:before {
  content: "\e924";
}

.icon-show:before {
  content: "\e925";
}

.icon-twitter:before {
  content: "\e926";
}

.icon-unlock:before {
  content: "\e927";
}

.icon-video:before {
  content: "\e928";
}

.icon-youTube:before {
  content: "\e929";
}

:root {
  --accent-red: #bd010f;
  --accent-green: #6c9712;
  --accent-blue: #0a3f73;
  --accent-purple: #602165;
  --accent-orange: #dd7b07;
}

body.color-red {
  --color: var(--accent-red);
}
body.color-green {
  --color: var(--accent-green);
}
body.color-blue {
  --color: var(--accent-blue);
}
body.color-purple {
  --color: var(--accent-purple);
}
body.color-orange {
  --color: var(--accent-orange);
}
body h1,
body h2,
body h3,
body h4,
body a:not([class]):hover,
body footer .footer-top-navigation ul li a:hover,
body footer .node_wrapper ul li a:hover,
body footer .node_wrapper ul li strong,
body #below-header .main-navigation .level_1 li > a.trail,
body #below-header .main-navigation .level_1 li > strong.active,
body .left-navigation .level_1 a.trail,
body .left-navigation .level_1 strong.active,
body .left-navigation .level_1 a:hover,
body .top-navigation a:hover,
body .top-navigation strong.active,
body .rsce-links__link:hover,
body .rsce-links__link:hover .rsce-links__headline,
body .header-search button:hover,
body .pagination li strong.active,
body .pagination li a:hover,
body .collection-date__link:hover.top-navigation,
body .header-trigger.active,
body .header-trigger:hover,
body .teaser-list--type-bubble .teaser-list__headline {
  color: var(--color);
}
body a:not([class]):hover,
body .pagination li a:hover {
  text-decoration-color: var(--color);
}
body .widget-submit button:hover,
body .teaser-list--type-standard .teaser-list__link:hover,
body .ce_hyperlink a:hover {
  background-color: var(--color);
}

@media only print {
  body #header .top-navigation,
  body #header .ce_form,
  body #header .header-collage,
  body #below-header,
  body .collection-date__headline,
  body .collection-date__container,
  body #left,
  body #right,
  body #footer {
    display: none !important;
  }
  body .header-container {
    position: relative;
    flex-direction: row-reverse;
  }
  body #main {
    margin-top: -50px;
    border: none !important;
    box-shadow: none !important;
  }
  body * {
    color: black !important;
  }
}
/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
*/
*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
*/
html {
  line-height: 1.5; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -moz-tab-size: 4; /* 3 */
  tab-size: 4; /* 3 */
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 4 */
  font-size: 1rem;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/
body {
  margin: 0; /* 1 */
  line-height: inherit; /* 2 */
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/
hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  background-color: currentColor;
  border-top-width: 1px; /* 3 */
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/
abbr:where([title]) {
  text-decoration: underline dotted;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

/*
Remove the default font size and weight for headings.
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/
a {
  color: inherit;
  text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/
b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

/*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/
code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/*
Add the correct font size in all browsers.
*/
small {
  font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/
table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
  border-collapse: collapse; /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: inherit; /* 1 */
  color: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/
button,
select {
  text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 1 */
  background-color: transparent; /* 2 */
  background-image: none; /* 2 */
}

/*
Use the modern Firefox focus style for all focusable elements.
*/
:-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/
:-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/
progress {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/
summary {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/
textarea {
  resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/
input::placeholder,
textarea::placeholder {
  opacity: 1; /* 1 */
}

/*
Set the default cursor for buttons.
*/
button,
[role=button] {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/
:disabled {
  cursor: default;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block; /* 1 */
  vertical-align: middle; /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/
img,
video {
  max-width: 100%;
  height: auto;
}

/*
Ensure the default browser behavior of the `hidden` attribute.
*/
[hidden] {
  display: none !important;
}

/* -------------------------------------------------------------
//  Basics
// ---------------------------------------------------------- */
:root {
  font-size: 16px;
}

body {
  background: url("../img/body_bg.png") top center;
  color: #1c1c28;
  font-family: ITCFranklinGothicW02-Bk, Helvetica, Arial;
  font-size: 1rem;
  line-height: 1.3125rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  min-height: 100vh;
  min-height: 100dvh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
body.fix {
  overflow: hidden;
}

/* -------------------------------------------------------------
//  Typo
// -----------------------------------------------------------*/
h1, .h1 {
  font-family: ITCFranklinGothicW02-Dm, Helvetica, Arial;
  font-weight: 800;
  font-size: clamp(1.25rem, 1.25rem + 0 * (100vw - 375px) / 1065, 1.25rem);
  line-height: clamp(1.5rem, 1.5rem + 0 * (100vw - 375px) / 1065, 1.5rem);
  margin-top: clamp(0rem, 0rem + 0 * (100vw - 375px) / 1065, 0rem);
  margin-bottom: clamp(0.9375rem, 0.9375rem + 0 * (100vw - 375px) / 1065, 0.9375rem);
}

h2, .h2 {
  font-family: ITCFranklinGothicW02-Dm, Helvetica, Arial;
  font-weight: 800;
  font-size: clamp(1rem, 1rem + 0 * (100vw - 375px) / 1065, 1rem);
  line-height: clamp(1.25rem, 1.25rem + 0 * (100vw - 375px) / 1065, 1.25rem);
  margin-top: clamp(1.5rem, 1.5rem + 0 * (100vw - 375px) / 1065, 1.5rem);
  margin-bottom: clamp(0.375rem, 0.375rem + 0 * (100vw - 375px) / 1065, 0.375rem);
}

h3, .h3 {
  font-family: ITCFranklinGothicW02-Dm, Helvetica, Arial;
  font-weight: 800;
  font-size: clamp(1rem, 1rem + 0 * (100vw - 375px) / 1065, 1rem);
  line-height: clamp(1.25rem, 1.25rem + 0 * (100vw - 375px) / 1065, 1.25rem);
  margin-top: clamp(1.5rem, 1.5rem + 0 * (100vw - 375px) / 1065, 1.5rem);
  margin-bottom: clamp(0.375rem, 0.375rem + 0 * (100vw - 375px) / 1065, 0.375rem);
}

h4, .h4 {
  font-family: ITCFranklinGothicW02-Bk, Helvetica, Arial;
  font-weight: 800;
  font-size: clamp(1.25rem, 1.25rem + 10 * (100vw - 375px) / 1065, 1.875rem);
  line-height: clamp(1.75rem, 1.75rem + 10 * (100vw - 375px) / 1065, 2.375rem);
  margin-top: clamp(0rem, 0rem + 0 * (100vw - 375px) / 1065, 0rem);
  margin-bottom: clamp(0rem, 0rem + 0 * (100vw - 375px) / 1065, 0rem);
}

h5, .h5 {
  font-family: ITCFranklinGothicW02-Bk, Helvetica, Arial;
  font-weight: 800;
  font-size: clamp(1.125rem, 1.125rem + 6 * (100vw - 375px) / 1065, 1.5rem);
  line-height: clamp(1.5rem, 1.5rem + 8 * (100vw - 375px) / 1065, 2rem);
  margin-top: clamp(0rem, 0rem + 0 * (100vw - 375px) / 1065, 0rem);
  margin-bottom: clamp(0rem, 0rem + 0 * (100vw - 375px) / 1065, 0rem);
}

h6, .h6 {
  font-family: ITCFranklinGothicW02-Bk, Helvetica, Arial;
  font-weight: 800;
  font-size: clamp(1rem, 1rem + 2 * (100vw - 375px) / 1065, 1.125rem);
  line-height: clamp(1.375rem, 1.375rem + 2 * (100vw - 375px) / 1065, 1.5rem);
  margin-top: clamp(0rem, 0rem + 0 * (100vw - 375px) / 1065, 0rem);
  margin-bottom: clamp(0rem, 0rem + 0 * (100vw - 375px) / 1065, 0rem);
}

.col-12 > h2,
.col-12 > h3,
.col-12 > h4,
.col-12 > h5,
.col-12 > h6 {
  margin-top: 0;
}

.font-size-display-1 {
  font-family: ITCFranklinGothicW02-Bk, Helvetica, Arial;
  font-weight: 800;
  font-size: clamp(3rem, 3rem + 32 * (100vw - 375px) / 1065, 5rem);
  line-height: clamp(3.5rem, 3.5rem + 24 * (100vw - 375px) / 1065, 5rem);
  margin-top: clamp(0rem, 0rem + 0 * (100vw - 375px) / 1065, 0rem);
  margin-bottom: clamp(0rem, 0rem + 0 * (100vw - 375px) / 1065, 0rem);
}

.font-size-display-2 {
  font-family: ITCFranklinGothicW02-Bk, Helvetica, Arial;
  font-weight: 800;
  font-size: clamp(2.5rem, 2.5rem + 30 * (100vw - 375px) / 1065, 4.375rem);
  line-height: clamp(3rem, 3rem + 28 * (100vw - 375px) / 1065, 4.75rem);
  margin-top: clamp(0rem, 0rem + 0 * (100vw - 375px) / 1065, 0rem);
  margin-bottom: clamp(0rem, 0rem + 0 * (100vw - 375px) / 1065, 0rem);
}

/* -------------------------------------------------------------
//  Images
// -----------------------------------------------------------*/
img {
  height: auto;
  width: 100%;
}

/* -------------------------------------------------------------
//  Links
// -----------------------------------------------------------*/
a:not([class]) {
  color: #7b7b7b;
  text-decoration: underline;
  text-decoration-color: #7b7b7b;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}
a:not([class]):hover {
  color: #444;
}

.link {
  color: #444;
  text-decoration: none;
}
.link--muted {
  border-bottom: #555770 1px solid;
  color: #555770;
  text-decoration: none;
}
.link--muted:hover {
  border-bottom: #1c1c28 1px solid;
  color: #1c1c28;
}

/* -------------------------------------------------------------
//  List
// -----------------------------------------------------------*/
.list {
  margin: 0;
  margin-bottom: 1.3125rem;
  padding: 0;
}
.list__item {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  margin: 0;
  margin-left: 0.875rem;
  margin-bottom: 1.3125rem;
  padding: 0;
}

.logo {
  display: block;
  margin: 0;
  width: 171px;
}
.logo img {
  margin-bottom: 0;
}

.logo--footer {
  width: 170px;
}

@media only screen and (min-width: 768px) {
  .logo {
    width: 195px;
  }
}
@media only screen and (min-width: 1200px) {
  .logo {
    width: 229px;
  }
}
p {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  margin-bottom: 1.3125rem;
}

.font-size-xxlarge {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 2rem;
}

.font-size-xlarge {
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 2rem;
}

.font-size-large {
  font-size: 1.125rem;
  line-height: 2rem;
  margin-bottom: 2rem;
}

small, .font-size-small {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}

.font-size-xsmall {
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}

.font-size-xxsmall {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}

strong {
  font-weight: 700;
}

.col-12 > p {
  margin-top: 0;
}

/* -------------------------------------------------------------
//  Section
// -----------------------------------------------------------*/
.section {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.section::before, .section::after {
  content: "";
  display: block;
}
.section::before {
  margin-bottom: 4rem;
}
.section::after {
  margin-top: 4rem;
}
.section--light {
  background-color: #e9e9f0;
}
.section--dark {
  background-color: #555770;
  color: #ffffff;
}
.section.section-padding-none {
  border: none;
}
.section.section-padding-none::before, .section.section-padding-none::after {
  display: none;
}
.section.section-padding-parking-sm::before {
  margin-bottom: clamp(12px, calc(9.1830985915px + 0.0075117371 * 100vw), 20px);
}
.section.section-padding-parking-sm::after {
  margin-top: clamp(12px, calc(9.1830985915px + 0.0075117371 * 100vw), 20px);
}
.section.section-padding-parking-md::before {
  margin-bottom: clamp(24px, calc(18.3661971831px + 0.0150234742 * 100vw), 40px);
}
.section.section-padding-parking-md::after {
  margin-top: clamp(24px, calc(18.3661971831px + 0.0150234742 * 100vw), 40px);
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.datepicker {
  width: min-content;
}
.datepicker:not(.active) {
  display: none;
}

.datepicker-dropdown {
  position: absolute;
  z-index: 20;
  padding-top: 4px;
}
.datepicker-dropdown.datepicker-orient-top {
  padding-top: 0;
  padding-bottom: 4px;
}

.datepicker-picker {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: hsl(0, 0%, 100%);
}
.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.datepicker-main {
  flex: auto;
  padding: 2px;
}

.datepicker-footer {
  box-shadow: inset 0 1px 1px rgba(10, 10, 10, 0.1);
  background-color: hsl(0, 0%, 96%);
}

.datepicker-title {
  box-shadow: inset 0 -1px 1px rgba(10, 10, 10, 0.1);
  background-color: hsl(0, 0%, 96%);
  padding: 0.375rem 0.75rem;
  text-align: center;
  font-weight: 700;
}

.datepicker-controls {
  display: flex;
}
.datepicker-header .datepicker-controls {
  padding: 2px 2px 0;
}
.datepicker-controls .button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 1px solid gainsboro;
  border-radius: 4px;
  box-shadow: none;
  background-color: hsl(0, 0%, 100%);
  cursor: pointer;
  padding: calc(0.375em - 1px) 0.75em;
  height: 2.25em;
  vertical-align: top;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap;
  color: hsl(0, 0%, 21%);
  font-size: 1rem;
}
.datepicker-controls .button:focus, .datepicker-controls .button:active {
  outline: none;
}
.datepicker-controls .button:hover {
  border-color: #b8b8b8;
  color: hsl(0, 0%, 21%);
}
.datepicker-controls .button:focus {
  border-color: hsl(217, 71%, 53%);
  color: hsl(0, 0%, 21%);
}
.datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}
.datepicker-controls .button:active {
  border-color: #474747;
  color: hsl(0, 0%, 21%);
}
.datepicker-controls .button[disabled] {
  cursor: not-allowed;
}
.datepicker-header .datepicker-controls .button {
  border-color: transparent;
  font-weight: bold;
}
.datepicker-header .datepicker-controls .button:hover {
  background-color: #f9f9f9;
}
.datepicker-header .datepicker-controls .button:active {
  background-color: #f2f2f2;
}
.datepicker-footer .datepicker-controls .button {
  flex: auto;
  margin: calc(0.375rem - 1px) 0.375rem;
  border-radius: 2px;
  font-size: 0.75rem;
}
.datepicker-controls .view-switch {
  flex: auto;
}
.datepicker-controls .prev-button,
.datepicker-controls .next-button {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  flex: 0 0 14.2857142857%;
}
.datepicker-controls .prev-button.disabled,
.datepicker-controls .next-button.disabled {
  visibility: hidden;
}

.datepicker-view,
.datepicker-grid {
  display: flex;
}

.datepicker-view {
  align-items: stretch;
  width: 15.75rem;
}

.datepicker-grid {
  flex-wrap: wrap;
  flex: auto;
}

.datepicker .days {
  display: flex;
  flex-direction: column;
  flex: auto;
}
.datepicker .days-of-week {
  display: flex;
}
.datepicker .week-numbers {
  display: flex;
  flex-direction: column;
  flex: 0 0 9.6774193548%;
}
.datepicker .weeks {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: auto;
}
.datepicker span {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: default;
  -webkit-touch-callout: none;
  user-select: none;
}
.datepicker .dow {
  height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 700;
}
.datepicker .week {
  flex: auto;
  color: #b8b8b8;
  font-size: 0.75rem;
}

.datepicker-cell, .datepicker .days .dow {
  flex-basis: 14.2857142857%;
}

.datepicker-cell {
  height: 2.25rem;
}
.datepicker-cell:not(.day) {
  flex-basis: 25%;
  height: 4.5rem;
}
.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.datepicker-cell.focused:not(.selected) {
  background-color: #e9e9e9;
}
.datepicker-cell.selected, .datepicker-cell.selected:hover {
  background-color: hsl(217, 71%, 53%);
  color: #fff;
  font-weight: 600;
}
.datepicker-cell.disabled {
  color: gainsboro;
}
.datepicker-cell.prev:not(.disabled), .datepicker-cell.next:not(.disabled) {
  color: hsl(0, 0%, 48%);
}
.datepicker-cell.prev.selected, .datepicker-cell.next.selected {
  color: #e6e6e6;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  border-radius: 0;
  background-color: hsl(0, 0%, 96%);
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #efefef;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e9e9e9;
}
.datepicker-cell.today:not(.selected) {
  background-color: hsl(171, 100%, 41%);
}
.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}
.datepicker-cell.today.focused:not(.selected) {
  background-color: #00ccad;
}
.datepicker-cell.range-end:not(.selected), .datepicker-cell.range-start:not(.selected) {
  background-color: #b8b8b8;
  color: #fff;
}
.datepicker-cell.range-end.focused:not(.selected), .datepicker-cell.range-start.focused:not(.selected) {
  background-color: #b3b3b3;
}
.datepicker-cell.range-start:not(.range-end) {
  border-radius: 4px 0 0 4px;
}
.datepicker-cell.range-end:not(.range-start) {
  border-radius: 0 4px 4px 0;
}
.datepicker-cell.range {
  border-radius: 0;
  background-color: gainsboro;
}
.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #d7d7d7;
}
.datepicker-cell.range.disabled {
  color: #c6c6c6;
}
.datepicker-cell.range.focused {
  background-color: #d1d1d1;
}

.datepicker-input.in-edit {
  border-color: #276bda;
}
.datepicker-input.in-edit:focus, .datepicker-input.in-edit:active {
  box-shadow: 0 0 0.25em 0.25em rgba(39, 107, 218, 0.2);
}

/* -------------------------------------------------------------
//  Accordion
// -----------------------------------------------------------*/
.Accordion {
  margin: 0;
  margin-bottom: 21;
  padding: 0;
}
.Accordion h3 {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
}
.Accordion.focus {
  border-color: #d8d9e3;
}

.Accordion > * + * {
  border-top: 1px solid #d8d9e3;
}

.Accordion-trigger {
  background: none;
  border-style: none;
  color: #1c1c28;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.3125rem;
  margin: 0;
  padding: 1em 4em 1em 1.5em;
  position: relative;
  text-align: left;
  width: 100%;
  outline: none;
}
.Accordion-trigger:focus, .Accordion-trigger:hover {
  background: rgba(197, 211, 236, 0.5215686275);
  border-color: #d8d9e3;
}

.Accordion button::-moz-focus-inner {
  border: 0;
}

.Accordion-title {
  display: block;
  pointer-events: none;
  border: transparent 2px solid;
  border-radius: 5px;
  padding: 0.25em;
  outline: none;
}

.Accordion-trigger:focus .Accordion-title {
  /*border-color: hsl(216, 94%, 73%);*/
}

.Accordion-icon {
  height: 1.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.875rem;
  top: 1.125rem;
  width: 1.3125rem;
  speak: none;
}

.Accordion-icon::after {
  content: "\e906";
  color: #0063f7;
  display: block;
  font-family: "iconfont" !important;
  font-size: 1.125rem;
  font-weight: 300;
}

.Accordion-trigger[aria-expanded=true] {
  border: none;
}

.Accordion-trigger[aria-expanded=true] .Accordion-icon {
  speak: none;
}

.Accordion-trigger[aria-expanded=true] .Accordion-icon::before {
  display: none;
}

.Accordion-trigger[aria-expanded=true] .Accordion-icon::after {
  content: "\e909";
}

.Accordion-panel {
  background-color: #ffffff;
  margin: 0;
  padding: 1em 1.5em;
}

.Accordion-panel[hidden] {
  display: none;
}

.Accordion-panel ul li {
  margin-bottom: 1em;
  padding-left: 30px;
}

/* -------------------------------------------------------------
//  Button
// -----------------------------------------------------------*/
.button {
  align-items: center;
  background-color: none;
  border: 1px solid #8f90a6;
  border-radius: 3px;
  color: #555770;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
}
.button > i {
  font-size: 1rem;
}
.button:hover {
  background-color: none;
  border: 1px solid #1c1c28;
  color: #1c1c28;
}
.button--primary {
  background-color: #0063f7;
  border: 1px solid #0063f7;
  color: #ffffff !important;
}
.button--primary:hover {
  background-color: #004fc4;
  border: 1px solid #004fc4;
}
.button--link {
  background-color: none;
  border: none;
  color: none;
}
.button--link:hover {
  background-color: none;
  border: #1c1c28 !important;
  color: #1c1c28;
}

/* -------------------------------------------------------------
//  Checkbox
// -----------------------------------------------------------*/
input[type=checkbox] {
  appearance: none;
  background-color: #fff;
  margin: 0 0.4375rem 0 0;
  font: inherit;
  color: currentColor;
  width: 1.3125rem;
  height: 1.3125rem;
  border: 1px solid #8f90a6;
  border-radius: 2px;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
}
input[type=checkbox]::before {
  content: "";
  width: 0.75em;
  height: 0.75em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #0063f7;
}
input[type=checkbox]:checked:before {
  transform: scale(1);
}

#container {
  align-self: flex-start;
  margin-top: 3.75rem;
  margin-bottom: 2.5rem;
}
@media only screen and (min-width: 576px) {
  #container {
    margin-top: 3.75rem;
  }
}
@media only screen and (min-width: 768px) {
  #container {
    margin-top: 2.5rem;
  }
}

#main {
  position: relative;
  align-self: flex-start;
}
@media only screen and (min-width: 992px) {
  #main:has(~ #left):before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid white;
  }
  #main:has(~ #right):after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid white;
  }
}

/* -------------------------------------------------------------
//  Cookielayer
// -----------------------------------------------------------*/
.cookie-layer {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  margin: 0;
  padding: 20px;
  left: 0;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1000;
  overflow-y: scroll;
  overflow-x: hidden;
}

.cookie-layer.is-hidden {
  display: none;
}

.cookie-layer__body {
  background-color: #ffffff;
  padding: 20px 20px 0 20px;
  height: calc(100% - 0px);
  /* overflow-y: scroll; */
  overflow-x: hidden;
}

.cookie-layer__wrapper {
  height: calc(100% - 170px);
  /* overflow-y: scroll; */
  overflow-x: hidden;
  overflow-y: scroll;
}

.trackingheadline {
  font-size: 1.5rem;
  font-weight: 700;
}

.cookiefieldset {
  border: 0 none;
  padding-left: 0;
}

.cookielist {
  display: block;
}

.cookiebuttons {
  padding-top: 20px;
  text-align: left;
}

.cookielist__item {
  list-style: none;
  margin-right: 20px;
  text-align: left;
  margin-bottom: 10px;
}

.checkbox--cookie {
  margin-right: 10px;
}

.accordion--cookie {
  background-color: #ffffff !important;
  padding-left: 0;
}

.accordion--cookie {
  margin: 0;
}

.accordion-title--cookie {
  margin-left: 30px;
}

.accordion-icon--cookie {
  top: 12px;
  left: 0;
  right: auto;
}

.accordion-panel--cookie {
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

@media only screen and (min-width: 450px) {
  .cookielist {
    display: flex;
  }
}
@media only screen and (min-width: 768px) {
  .cookie-layer,
  .cookie-layer__wrapper {
    overflow-y: hidden;
  }
  .cookie-layer__body {
    height: auto;
    overflow-y: hidden;
    max-width: 900px;
    margin: 0 auto;
  }
  .cookiebuttons {
    text-align: right;
  }
}
footer {
  padding-bottom: 2rem;
  color: #e9e9f0;
  background-color: rgba(0, 0, 0, 0.0588235294);
}
footer .footer-top-navigation ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.75rem 0 0;
  padding: 0 0 0.75rem;
  list-style-type: none;
}
footer .footer-top-navigation ul li a,
footer .footer-top-navigation ul li span,
footer .footer-top-navigation ul li strong {
  display: block;
  margin: 0.25rem 0;
  padding: 0.25rem 0.875rem;
  font-size: 0.85rem;
  line-height: 1.275rem;
  color: #7b7d7d;
}
footer .footer-top-navigation ul li a {
  text-decoration: none;
  border: none;
  transition: color 350ms ease;
}
footer .footer-top-navigation ul li a:hover {
  color: #dd7b07;
  border: none;
}
@media only screen and (min-width: 768px) {
  footer .footer-top-navigation ul {
    flex-direction: row;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0 1px 0 #ffffff;
  }
  footer .footer-top-navigation ul li:first-of-type > * {
    margin-left: 0;
    padding-left: 0;
  }
  footer .footer-top-navigation ul li a,
  footer .footer-top-navigation ul li span,
  footer .footer-top-navigation ul li strong {
    margin: 0 0.625rem;
    padding: 0.25rem 0.5rem;
  }
}
footer .node_wrapper {
  display: none;
  margin-top: 2rem;
}
footer .node_wrapper h4 {
  font-family: "ITCFranklinGothicW02-Dm";
  font-size: 0.75rem;
  line-height: 1.1875rem;
  font-weight: 600;
  color: #7b7d7d;
}
footer .node_wrapper ul {
  display: flex;
  flex-direction: column;
}
footer .node_wrapper ul li a,
footer .node_wrapper ul li span,
footer .node_wrapper ul li strong {
  font-size: 0.75rem;
  line-height: 1.1875rem;
  color: #bbbbbb;
}
footer .node_wrapper ul li a {
  text-decoration: none;
  border: none;
  transition: color 350ms ease;
}
footer .node_wrapper ul li a:hover {
  color: #dd7b07;
  border: none;
}
footer .node_wrapper ul li strong {
  font-weight: 400;
  color: #dd7b07;
}
@media only screen and (min-width: 768px) {
  footer .node_wrapper {
    display: block;
  }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f90a6;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #8f90a6;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #8f90a6;
}

form.grid-form .formbody {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.4375rem;
}
form h2 {
  margin-bottom: 0;
  color: #5c5c5c;
}
form .columns-1.as-row {
  display: flex;
  align-items: center;
  grid-column: 1/-1;
}
form .columns-1.as-row .widget-text {
  width: 20%;
}
form .columns-1.as-row .widget-explanation p {
  margin: 0;
}

.widget {
  position: relative;
}
.widget.hide {
  display: none !important;
}
.widget.columns-1 {
  grid-column: 1/-1;
}
.widget.columns-2 {
  grid-column: 1/3;
}
.widget.width-20 input {
  max-width: 20%;
}
.widget.border-top {
  margin-top: 0.625rem;
  padding-top: 0.625rem;
  border-top: 1px solid white;
  box-shadow: 0 -1px 0 #e0e0e0;
}
.widget.border-bottom {
  margin-bottom: 0.625rem;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 1px 0 white;
}
.widget.border-bottom p:last-of-type {
  margin: 0;
}
.widget.border-top.border-bottom {
  box-shadow: 0 1px 0 white, 0 -1px 0 #e0e0e0;
}
.widget.align-reverse {
  display: flex;
  align-items: center;
}
.widget.align-reverse label {
  margin-left: 0.5rem;
}
.widget.label-overlay ::placeholder {
  color: transparent;
}
.widget label:after {
  content: none;
}
.widget label.label-overlay {
  position: absolute;
  top: 0;
  left: 0.5rem;
  line-height: 1.75rem;
  color: #7b7d7d;
  cursor: text;
}
.widget label.label-overlay .mandatory {
  padding-left: 0.25rem;
  font-family: "ITCFranklinGothicW02-Dm";
  color: #bd010f;
}
.widget-explanation p:first-of-type {
  margin-top: 0.5rem;
}
.widget-explanation p:last-of-type {
  margin-bottom: -0.375rem;
}
.widget-text input, .widget-textarea textarea {
  margin: 0;
  padding: 0.1875rem 0.5rem;
  width: 100%;
  color: #444444;
  border-radius: 0.375rem;
  border: 1px solid #e2e2e2;
  background: #fbfaf8;
}
.widget-text input.label-overlay:focus + label, .widget-text input.label-overlay:not(:placeholder-shown) + label, .widget-textarea textarea.label-overlay:focus + label, .widget-textarea textarea.label-overlay:not(:placeholder-shown) + label {
  display: none;
}
.widget-textarea {
  margin-top: 0.5rem;
}
.widget-checkbox, .widget-textarea {
  grid-column: 1/-1;
}
.widget-checkbox {
  margin: 1rem 0;
}
.widget-checkbox .block {
  display: flex;
}
.widget-checkbox .block input {
  flex-shrink: 0;
}
.widget-checkbox .checkbox_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.widget-checkbox .checkbox_container span {
  display: flex;
}
.widget-submit button {
  overflow: hidden;
  display: inline-block;
  padding: 0.125rem 1.875rem;
  line-height: 1.5625rem;
  font-family: "ITCFranklinGothicW02-Dm";
  color: white;
  border-radius: 30px;
  border: none;
  background: #707070;
  transition: background 350ms ease;
}

@media only screen and (min-width: 768px) {
  .contact__form .formbody {
    grid-template-columns: 1fr 1fr;
  }
}
header {
  width: 100% !important;
  background-color: rgba(255, 255, 255, 0.6980392157);
  z-index: 10;
}
header .header-container {
  display: flex;
  justify-content: space-between;
}
header .header-container .space {
  flex: 1;
}
header .header-logo {
  position: relative;
  align-self: flex-start;
  margin-top: 1.4375rem;
  z-index: 100;
}
header .header-logo img {
  height: 3.75rem;
}
header .header-trigger {
  margin-top: 0.3125rem;
  margin-right: 1rem;
  font-family: "Iconfont";
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: #8e8e8e;
  cursor: pointer;
  z-index: 100;
  transition: color 350ms ease;
}
header .header-search {
  position: relative;
  z-index: 100;
}
header .header-search.open input {
  width: 8.75rem;
  border-color: #e2e2e2;
  background: #fbfaf8;
  transition: all 350ms ease;
}
header .header-search.open input ::placeholder {
  color: #e2e2e2;
}
header .header-search .formbody {
  display: flex;
  margin-top: 0.3125rem;
}
header .header-search input {
  padding: 0 0.4375rem;
  width: 1.875rem;
  color: #8e8e8e;
  border-color: transparent;
  border-radius: 1.25rem;
  background: none;
}
header .header-search input::placeholder {
  color: transparent;
}
header .header-search button {
  margin-left: -1.5rem;
  padding: 0;
  font-family: "evbw";
  font-size: 1rem;
  line-height: 1.5625rem;
  color: #8e8e8e;
  background: none !important;
  transition: color 350ms ease;
}
header .top-navigation {
  display: none;
  margin-top: 0.25rem;
}
header .top-navigation ul {
  display: flex;
}
header .top-navigation li {
  margin-right: 1.625rem;
}
header .top-navigation a,
header .top-navigation strong {
  font-size: 0.875rem;
  line-height: 1.625rem;
  color: #8e8e8e;
}
@media only screen and (min-width: 768px) {
  header .top-navigation {
    display: block;
  }
  header .header-logo img {
    height: 5rem;
  }
  header .header-trigger {
    display: none;
  }
  header .header-search input {
    width: 8.75rem;
    border-color: #e2e2e2;
    background: #fbfaf8;
  }
  header .header-search input::placeholder {
    color: #e2e2e2;
  }
}

#below-header {
  display: none;
  margin-top: 3.5625rem;
}
#below-header .main-navigation .level_1 {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #e0e0e0;
  -webkit-box-shadow: 0 1px 0 white;
}
#below-header .main-navigation .level_1 li {
  border-left: 1px solid white;
  box-shadow: -1px 0 0 #e0e0e0;
}
#below-header .main-navigation .level_1 li.color-red > a:hover,
#below-header .main-navigation .level_1 li.color-red > strong {
  color: var(--accent-red);
}
#below-header .main-navigation .level_1 li.color-green > a:hover,
#below-header .main-navigation .level_1 li.color-green > strong {
  color: var(--accent-green);
}
#below-header .main-navigation .level_1 li.color-blue > a:hover,
#below-header .main-navigation .level_1 li.color-blue > strong {
  color: var(--accent-blue);
}
#below-header .main-navigation .level_1 li.color-purple > a:hover,
#below-header .main-navigation .level_1 li.color-purple > strong {
  color: var(--accent-purple);
}
#below-header .main-navigation .level_1 li.color-orange > a:hover,
#below-header .main-navigation .level_1 li.color-orange > strong {
  color: var(--accent-orange);
}
#below-header .main-navigation .level_1 li > a,
#below-header .main-navigation .level_1 li > strong {
  position: relative;
  display: block;
  padding: 1.5rem 1rem 0.375rem;
  font-family: "ITCFranklinGothicW02-Dm";
  font-weight: 700;
  font-size: clamp(12px, calc(9.8873239437px + 0.0056338028 * 100vw), 18px);
  line-height: clamp(20px, calc(17.5352112676px + 0.00657277 * 100vw), 27px);
  color: #707070;
  text-decoration: none;
  border: none;
}
#below-header .main-navigation .level_1 li > a:before,
#below-header .main-navigation .level_1 li > strong:before {
  position: absolute;
  top: 0;
  content: "\e001";
  font-family: "evbw";
  transition: top 350ms ease;
}
#below-header .main-navigation .level_1 li > a:hover:before,
#below-header .main-navigation .level_1 li > strong:hover:before {
  top: 0.125rem;
}
@media only screen and (min-width: 768px) {
  #below-header {
    display: block;
  }
}

/* -------------------------------------------------------------
//  Navigation
// -----------------------------------------------------------*/
.left-navigation .level_1 > li {
  padding: 0.25rem 0;
}
.left-navigation .level_1 > li:not(:last-of-type) {
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 1px 0 white;
}
.left-navigation .level_1 a,
.left-navigation .level_1 strong {
  display: inline-block;
  padding: 0.375rem 0;
  font-family: "ITCFranklinGothicW02-Dm";
  font-size: clamp(15px, calc(15px + 0 * 100vw), 15px);
  line-height: clamp(18px, calc(18px + 0 * 100vw), 18px);
  color: #444444;
  text-decoration: none;
  border: none;
  transition: color 350ms ease;
}
.left-navigation .level_2 {
  padding-left: 0.875rem;
}
.left-navigation .level_2 a,
.left-navigation .level_2 strong {
  display: inline-block;
  margin: 0.25rem 0;
  padding: 0.25rem 0;
  font-size: clamp(14px, calc(14px + 0 * 100vw), 14px);
  line-height: clamp(17px, calc(17px + 0 * 100vw), 17px);
  color: #8a8a8a;
}
.left-navigation .level_3 {
  padding-left: 0.875rem;
}
.left-navigation .level_4 {
  padding-left: 0.875rem;
}
.left-navigation .level_5 {
  padding-left: 0.875rem;
}

.mobile-navigation {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: opacity 250ms linear, max-height 350ms linear 250ms;
}
.mobile-navigation.active {
  max-height: 320px;
  opacity: 1;
  transition: max-height 350ms linear, opacity 250ms linear 350ms;
}
.mobile-navigation ul {
  margin: 0 0 clamp(32px, calc(26.3661971831px + 0.0150234742 * 100vw), 48px);
}
.mobile-navigation ul li {
  position: relative;
  margin: 0;
  padding: 0.5625rem 0;
  border-top: 1px solid #e0e0e0;
}
.mobile-navigation ul li::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  border-top: 1px solid white;
}
.mobile-navigation ul li:last-of-type {
  border-bottom: 1px solid white;
}
.mobile-navigation ul li:last-of-type:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0;
  border-bottom: 1px solid #e0e0e0;
}
.mobile-navigation ul li.color-red a:hover,
.mobile-navigation ul li.color-red strong {
  color: var(--accent-red);
}
.mobile-navigation ul li.color-green a:hover,
.mobile-navigation ul li.color-green strong {
  color: var(--accent-green);
}
.mobile-navigation ul li.color-blue a:hover,
.mobile-navigation ul li.color-blue strong {
  color: var(--accent-blue);
}
.mobile-navigation ul li.color-purple a:hover,
.mobile-navigation ul li.color-purple strong {
  color: var(--accent-purple);
}
.mobile-navigation ul li.color-orange a:hover,
.mobile-navigation ul li.color-orange strong {
  color: var(--accent-orange);
}
.mobile-navigation ul li a,
.mobile-navigation ul li strong {
  position: relative;
  display: block;
  font-family: "ITCFranklinGothicW02-Dm";
  font-size: 1.125rem;
  line-height: 1.6875rem;
  color: #707070;
  transition: color 350ms ease;
}
.mobile-navigation ul li a:after,
.mobile-navigation ul li strong:after {
  content: "\e908";
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Iconfont";
  font-weight: 700;
}
@media only screen and (min-width: 768px) {
  .mobile-navigation {
    display: none;
  }
}

.nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.nav__container {
  background-color: #ffffff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  inset: 0 0 0 0;
  position: fixed;
  transition: all 500ms ease-in-out;
}
@media (min-width: 1200px) {
  .nav__container {
    visibility: visible;
    opacity: 1;
    background: none;
    position: relative;
    top: auto !important;
    display: flex;
    flex-direction: row;
  }
}
.nav__container.show {
  visibility: visible;
  opacity: 1;
  overflow: auto;
}
.nav__list {
  position: relative;
}
.nav__item {
  text-align: center;
}
@media only screen and (min-width: 1200px) {
  .nav__item {
    display: inline;
  }
}
.nav__link {
  color: #1c1c28 !important;
  font-family: ITCFranklinGothicW02-Bk, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  display: block;
  text-decoration: none;
  margin: 0;
  padding: 15px;
  position: relative;
}
@media only screen and (min-width: 1200px) {
  .nav__link {
    display: inline-block;
    padding: 0;
    margin: 0 7px 0 10px;
  }
}
.nav__link:hover, .nav__link:focus {
  color: #0063f7 !important;
}
@media only screen and (min-width: 1200px) {
  .nav__link:hover, .nav__link:focus {
    color: #0063f7 !important;
  }
}
.nav__link.active {
  color: #0063f7 !important;
}
@media only screen and (min-width: 1200px) {
  .nav__link.active {
    color: #0063f7 !important;
  }
}

.nav__toggle-icon {
  cursor: pointer;
  height: 20px;
  position: relative;
  width: 20px;
  z-index: 15;
  transition: top 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.nav__toggle-icon > i {
  font-size: 1.3125rem;
}

/* Burgericon */
.burger-line {
  background-color: #1c1c28;
  display: block;
  height: 2px;
  position: absolute;
  width: 20px;
}

.burger-line--top {
  left: 0;
  top: 4px;
}

.burger-line--middle {
  left: 0;
  top: 11px;
}

.burger-line--bottom {
  left: 0px;
  top: 18px;
}

.nav__toggle-icon.toggle .burger-line--top {
  animation: line-top-animation 400ms 0s 1 forwards;
}

@keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
.nav__toggle-icon.toggle .burger-line--bottom {
  animation: line-bottom-animation 400ms 0s 1 forwards;
}

@keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
.nav__toggle-icon.toggle .burger-line--middle {
  animation: line-middle-animation 400ms 0s 1 forwards;
}

@-webkit-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
@keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
.nav__toggle-icon .burger-line--top {
  animation: line-top-animation-back 400ms 0s 1 forwards;
}

@keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
.nav__toggle-icon .burger-line--bottom {
  animation: line-bottom-animation-back 400ms 0s 1 forwards;
}

@keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
.nav__toggle-icon .burger-line--middle {
  animation: line-center-animation-back 400ms 0s 1 forwards;
}

@keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
/* -------------------------------------------------------------
//  Radio Buttons
    https://moderncss.dev/pure-css-custom-checkbox-style/
// -----------------------------------------------------------*/
input[type=radio] {
  appearance: none;
  background-color: #fff;
  margin: 0 0.4375rem 0 0;
  font: inherit;
  color: currentColor;
  width: 1.3125rem;
  height: 1.3125rem;
  border: 1px solid #8f90a6;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
}
input[type=radio]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #0063f7;
}
input[type=radio]:checked:before {
  transform: scale(1);
}

.textfield {
  margin-bottom: 1.3125rem;
}
.textfield__label {
  display: block;
  font-family: ITCFranklinGothicW02-Bk, Arial, sans-serif;
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin-bottom: 0.25rem;
  pointer-events: none;
}
.textfield__input {
  background-color: #ffffff;
  border: 1px solid #8f90a6;
  border-radius: 3px;
  color: #1c1c28;
  display: block;
  font-family: ITCFranklinGothicW02-Bk, Arial, sans-serif;
  font-size: 14;
  padding-left: 0.875rem;
  height: 48px;
  width: 100%;
}
.textfield__input:focus {
  outline: 3px solid rgba(0, 99, 247, 0.5);
}
.textarea {
  margin-bottom: 1.3125rem;
}
.textarea__label {
  display: block;
  font-family: ITCFranklinGothicW02-Bk, Arial, sans-serif;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  pointer-events: none;
}
.textarea__input {
  background-color: #ffffff;
  border: 1px solid #8f90a6;
  border-radius: 3px;
  color: #1c1c28;
  display: block;
  font-family: ITCFranklinGothicW02-Bk, Arial, sans-serif;
  padding-top: 0.75rem;
  padding-left: 0.875rem;
  height: 48px;
  width: 100%;
}
.textarea__input:focus {
  outline: 3px solid rgba(0, 99, 247, 0.5);
}

/* -------------------------------------------------------------
//  Toggle Select
// -----------------------------------------------------------*/
/*
.toggle-switch:has([type=checkbox]){
  height: 0;
  width: 0;
  visibility: hidden;
}
*/
.toggle-switch:has(label) {
  cursor: pointer;
  text-indent: -9999px;
  width: 4rem;
  height: 2rem;
  background: #dcdde5;
  display: block;
  border-radius: 100px;
  position: relative;
}

label:after {
  content: "";
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  background: #ffffff;
  border-radius: 100px;
  transition: 0.3s;
}

.toggle-switch:has(input:checked + label) {
  background: #0063f7;
}

input:checked + label:after {
  left: calc(100% - 0.25rem);
  transform: translateX(-100%);
}

label:active:after {
  width: 2rem;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid white;
  box-shadow: 0 -1px 0 #e0e0e0;
}
.pagination p {
  color: #7b7d7d;
}
.pagination ul {
  display: flex;
}
.pagination li {
  margin: 0 0.25rem;
}
.pagination li strong {
  padding: 0 0.25rem;
  font-family: "ITCFranklinGothicW02-Dm";
}
.pagination li a {
  padding: 0 0.25rem;
  color: #7b7d7d;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: #7b7d7d;
  text-underline-offset: 2px;
  transition: all 350ms ease;
}

/* -------------------------------------------------------------
//  Colors
// -----------------------------------------------------------*/
.color-black {
  color: #000000 !important;
}

.color-black-90 {
  color: #1c1c28 !important;
}

.color-black-80 {
  color: #28293d !important;
}

.color-black-70 {
  color: #555770 !important;
}

.color-black-60 {
  color: #8f90a6 !important;
}

.color-black-50 {
  color: #c7c9d9 !important;
}

.color-black-40 {
  color: #d8d9e3 !important;
}

.color-black-30 {
  color: #dcdde5 !important;
}

.color-black-20 {
  color: #e1e2eb !important;
}

.color-black-10 {
  color: #e9e9f0 !important;
}

.color-black-05 {
  color: #eeeff5 !important;
}

.color-black-01 {
  color: #fafafc !important;
}

.color-white {
  color: #ffffff !important;
}

.color-primary-dark {
  color: #004fc4 !important;
}

.color-primary {
  color: #0063f7 !important;
}

.color-primary-light {
  color: #9dbff9 !important;
}

.color-secondary-dark {
  color: #e57a00 !important;
}

.color-secondary {
  color: #ff8800 !important;
}

.color-secondary-light {
  color: #fccc75 !important;
}

.color-accent-dark {
  color: #4d0099 !important;
}

.color-accent {
  color: #6600cc !important;
}

.color-accent-light {
  color: #dda5e9 !important;
}

.color-danger-dark {
  color: #e53535 !important;
}

.color-danger {
  color: #ff3b3b !important;
}

.color-danger-light {
  color: #ff8080 !important;
}

.color-info-dark {
  color: #00b7c4 !important;
}

.color-info {
  color: #00cfde !important;
}

.color-info-light {
  color: #a9eff2 !important;
}

.color-success-dark {
  color: #05a660 !important;
}

.color-success {
  color: #06c270 !important;
}

.color-success-light {
  color: #57eba1 !important;
}

.color-warning-dark {
  color: #e5b800 !important;
}

.color-warning {
  color: #ffcc00 !important;
}

.color-warning-light {
  color: #fded72 !important;
}

/* -------------------------------------------------------------
//  Background-Colors
// -----------------------------------------------------------*/
.bg-color-black {
  background-color: #000000 !important;
}

.bg-color-black-90 {
  background-color: #1c1c28 !important;
}

.bg-color-black-80 {
  background-color: #28293d !important;
}

.bg-color-black-70 {
  background-color: #555770 !important;
}

.bg-color-black-60 {
  background-color: #8f90a6 !important;
}

.bg-color-black-50 {
  background-color: #c7c9d9 !important;
}

.bg-color-black-40 {
  background-color: #d8d9e3 !important;
}

.bg-color-black-30 {
  background-color: #dcdde5 !important;
}

.bg-color-black-20 {
  background-color: #e1e2eb !important;
}

.bg-color-black-10 {
  background-color: #e9e9f0 !important;
}

.bg-color-black-05 {
  background-color: #eeeff5 !important;
}

.bg-color-black-01 {
  background-color: #fafafc !important;
}

.bg-color-white {
  background-color: #ffffff !important;
}

.bg-color-primary-dark {
  background-color: #004fc4 !important;
}

.bg-color-primary {
  background-color: #0063f7 !important;
}

.bg-color-primary-light {
  background-color: #9dbff9 !important;
}

.bg-color-secondary-dark {
  background-color: #e57a00 !important;
}

.bg-color-secondary {
  background-color: #ff8800 !important;
}

.bg-color-secondary-light {
  background-color: #fccc75 !important;
}

.bg-color-accent-dark {
  background-color: #4d0099 !important;
}

.bg-color-accent {
  background-color: #6600cc !important;
}

.bg-color-accent-light {
  background-color: #dda5e9 !important;
}

.bg-color-danger-dark {
  background-color: #e53535 !important;
}

.bg-color-danger {
  background-color: #ff3b3b !important;
}

.bg-color-danger-light {
  background-color: #ff8080 !important;
}

.bg-color-info-dark {
  background-color: #00b7c4 !important;
}

.bg-color-info {
  background-color: #00cfde !important;
}

.bg-color-info-light {
  background-color: #a9eff2 !important;
}

.bg-color-success-dark {
  background-color: #05a660 !important;
}

.bg-color-success {
  background-color: #06c270 !important;
}

.bg-color-success-light {
  background-color: #57eba1 !important;
}

.bg-color-warning-dark {
  background-color: #e5b800 !important;
}

.bg-color-warning {
  background-color: #ffcc00 !important;
}

.bg-color-warning-light {
  background-color: #fded72 !important;
}

/* -------------------------------------------------------------
//  Helper
// -----------------------------------------------------------*/
.sticky-top {
  position: sticky;
  top: 0;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-emphasis: right;
}

.flex, .flexbox {
  display: flex;
}

.uppercase {
  text-transform: uppercase;
}

.display-block {
  display: block !important;
}

.invisible {
  display: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.font-size-xxlarge {
  font-size: 1.5rem;
}

.font-size-xlarge {
  font-size: 1.25rem;
}

.font-size-large {
  font-size: 1.125rem;
}

.font-size-medium {
  font-size: 0.875rem;
}

.font-size-small {
  font-size: 0.875rem;
}

.font-size-xsmall {
  font-size: 0.75rem;
}

.font-size-xxsmall {
  font-size: 0.625rem;
}

.space-xxsmall, .size-xxsmall {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.space-xsmall, .size-xsmall {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.space-small, .size-small {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.space-medium, .size-medium {
  margin-right: 1rem;
  margin-left: 1rem;
}

.space-large, .size-large {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.space-xlarge, .size-xlarge {
  margin-right: 2rem;
  margin-left: 2rem;
}

.space-lxxarge, .size-xxlarge {
  margin-right: 3rem;
  margin-left: 3rem;
}

.space-section, .size-section {
  margin-right: 4rem;
  margin-left: 4rem;
}

.rsce-links__item:not(:first-of-type) {
  margin-top: 0.625rem;
}
.rsce-links__link {
  position: relative;
  display: block;
  padding: 0.875rem 1.75rem 0.875rem 0.875rem;
  background: rgba(0, 0, 0, 0.0509803922);
  transition: background 350ms ease, color 350ms ease;
}
.rsce-links__link:hover {
  background: white;
}
.rsce-links__link:after {
  content: "\e002";
  position: absolute;
  top: 50%;
  right: 0.5rem;
  font-family: "evbw";
  color: #b2b2b2;
  transform: translateY(-50%);
}
.rsce-links__headline {
  margin: 0;
  color: #5c5c5c;
  transition: color 350ms ease;
}
.rsce-links__text {
  margin: 0.1875rem 0 0;
}
.rsce-links__text p:last-of-type {
  margin-bottom: 0;
}

.ce_hyperlink a {
  overflow: hidden;
  display: inline-block;
  padding: 0.125rem 1.875rem;
  line-height: 1.5625rem;
  font-family: "ITCFranklinGothicW02-Dm";
  color: white;
  border-radius: 30px;
  border: none;
  background: #707070;
  transition: background 350ms ease;
}

.ce_table {
  margin-bottom: 1.3125rem;
}
.ce_table th,
.ce_table td {
  padding: 0.25rem 0.375rem 0.25rem 0;
}

.ce_text {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #444444;
}
.ce_text strong {
  font-family: "ITCFranklinGothicW02-Dm";
}
.ce_text ul,
.ce_text ol {
  margin-bottom: 1.5rem;
  padding-left: 1.25rem;
}
.ce_text ul li,
.ce_text ol li {
  margin-bottom: 0.4375rem;
}
.ce_text ul p,
.ce_text ol p {
  margin: 0;
}
.ce_text ul {
  list-style-type: disc;
}
.ce_text ol {
  list-style-type: decimal;
}

.ce_image {
  margin-bottom: 1.3125rem;
}
.ce_image img {
  width: auto;
}
.ce_image .caption {
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0.25rem 0 1.3125rem;
}

.collection-date__container {
  position: relative;
  margin-top: 4.6875rem;
  padding: 2.5rem 1.25rem 1.25rem;
  background: rgba(255, 255, 255, 0.7019607843);
}
.collection-date__form {
  display: flex;
  padding-left: 0;
}
.collection-date__input {
  flex: 1;
  padding: 0.375rem 1.25rem;
  border-radius: 30px;
  border: 1px solid #ededed;
  background: #ededed;
  transition: background 350ms ease;
}
.collection-date__input::-webkit-calendar-picker-indicator {
  display: none !important;
}
.collection-date__input:focus {
  background: white;
}
.collection-date__button {
  margin-left: 0.9375rem;
  width: 2.125rem;
  height: 2.125rem;
  font-family: "evbw";
  font-size: 1.3125rem;
  line-height: 1.3125rem;
  color: white;
  border-radius: 1.25rem;
  background: #bd010f;
}
.collection-date__image {
  position: absolute;
  bottom: 4.375rem;
  left: 1.25rem;
  width: 8.125rem;
  height: auto;
}
.collection-date__list {
  display: flex;
  flex-direction: column;
}
.collection-date__item {
  display: inline-flex;
  color: #7b7d7d;
}
.collection-date__item:before {
  content: "\e002";
  margin-right: 0.625rem;
  font-family: "evbw";
}
.collection-date__item:not(:first-of-type) {
  margin-top: 0.4375rem;
}
.collection-date__link {
  transition: color 350ms ease;
}
.collection-date__result .h2 {
  margin-top: 0;
}
.collection-date__result-list {
  color: #5c5c5c;
}
.collection-date__result-item {
  display: flex;
  padding: 0.875rem 0 0.875rem 0.8125rem;
}
.collection-date__result-item:not(:first-of-type) {
  border-top: 1px solid white;
  box-shadow: 0 -1px 0 #e8e8e8;
}
.collection-date__result-image {
  width: auto;
  height: 4.6875rem;
}
.collection-date__result-content {
  padding-left: 1.25rem;
}
.collection-date__result-type {
  font-family: "ITCFranklinGothicW02-Dm";
}
.collection-date__result-text {
  margin-top: 2rem;
}
.collection-date__result-text strong {
  font-family: "ITCFranklinGothicW02-Dm";
}
@media only screen and (min-width: 768px) {
  .collection-date__container {
    margin-top: 2.8125rem;
    padding-top: 1.25rem;
  }
  .collection-date__form {
    padding-left: 8.75rem;
  }
  .collection-date__image {
    bottom: 1.25rem;
  }
}

.header-collage {
  margin-top: -3.5rem;
  height: 82px;
}
.header-collage__container {
  position: relative;
  display: flex;
  align-items: stretch;
  height: 120px;
}
.header-collage__layer-back, .header-collage__layer-left, .header-collage__layer-right, .header-collage__layer-front {
  position: absolute;
  top: 0;
  bottom: 0;
}
.header-collage__layer-back {
  right: 0;
  left: 0;
  background: none;
  z-index: 10;
}
.header-collage__layer-left {
  right: 50%;
  left: 0;
  background: none;
  z-index: 20;
}
.header-collage__layer-right {
  right: 0;
  left: 50%;
  background: none;
  z-index: 20;
}
.header-collage__layer-front {
  right: 0;
  left: 0;
  background: url("../img/header/layer-front__small.png") top center no-repeat;
  background-size: contain;
  z-index: 30;
}
.header-collage--truck .header-collage__layer-front {
  background: url("../img/header/layer-front__small--truck.png") top center no-repeat;
  background-size: contain;
}
.header-collage--garbage .header-collage__layer-front {
  background: url("../img/header/layer-front__small--garbage.png") top center no-repeat;
  background-size: contain;
}
.header-collage--drainage .header-collage__layer-front {
  background: url("../img/header/layer-front__small--drainage.png") top center no-repeat;
  background-size: contain;
}
.header-collage--parking .header-collage__layer-front {
  background: url("../img/header/layer-front__small--parking.png") top center no-repeat;
  background-size: contain;
}
@media only screen and (min-width: 576px) {
  .header-collage {
    height: 125px;
  }
  .header-collage__container {
    height: 182px;
  }
}
@media only screen and (min-width: 768px) {
  .header-collage__layer-back {
    right: 0;
    left: 0;
    background: url("../img/header/layer-back__medium.png") top center no-repeat;
    background-size: contain;
  }
  .header-collage__layer-left {
    right: 50%;
    left: 0;
    background: url("../img/header/layer-left__medium.png") top center no-repeat;
    background-size: contain;
  }
  .header-collage__layer-right {
    right: 0;
    left: 50%;
    background: url("../img/header/layer-right__medium.png") top center no-repeat;
    background-size: contain;
  }
  .header-collage__layer-front {
    right: 0;
    left: 0;
    background: url("../img/header/layer-front__large.png") top center no-repeat;
    background-size: contain;
  }
  .header-collage--truck .header-collage__layer-front {
    background: url("../img/header/layer-front__large--truck.png") top center no-repeat;
    background-size: contain;
  }
  .header-collage--garbage .header-collage__layer-front {
    background: url("../img/header/layer-front__large--garbage.png") top center no-repeat;
    background-size: contain;
  }
  .header-collage--drainage .header-collage__layer-front {
    background: url("../img/header/layer-front__large--drainage.png") top center no-repeat;
    background-size: contain;
  }
  .header-collage--parking .header-collage__layer-front {
    background: url("../img/header/layer-front__large--parking.png") top center no-repeat;
    background-size: contain;
  }
}
@media only screen and (min-width: 1200px) {
  .header-collage__layer-back {
    background: url("../img/header/layer-back__large.png") top center no-repeat;
    background-size: contain;
  }
  .header-collage__layer-left {
    background: url("../img/header/layer-left__large.png") top center no-repeat;
    background-size: contain;
  }
  .header-collage__layer-right {
    background: url("../img/header/layer-right__large.png") top center no-repeat;
    background-size: contain;
  }
  .header-collage__layer-front {
    background: url("../img/header/layer-front__large.png") top center no-repeat;
    background-size: contain;
  }
}

.mod_search .formbody {
  display: flex;
}
.mod_search .widget-text {
  flex: 1;
}
.mod_search .widget-text input {
  padding: 0.1875rem 0.4375rem;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid #e2e2e2;
  border-bottom-color: #cccccc;
  background: #fbfaf8;
}
.mod_search .widget-submit button {
  margin-left: 0.25rem;
  padding: 0;
  font-family: "evbw";
  font-size: 1.1875rem;
  line-height: 1.875rem;
  color: #444444;
  background: none;
}
.mod_search .header {
  margin: 1rem 0 0;
}
.mod_search .info {
  color: #8e8e8e;
}
.mod_search > div.first {
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid white;
  box-shadow: 0 -1px 0 #e0e0e0;
}
.mod_search > div:not(:first-of-type) {
  margin-top: 2rem;
}
.mod_search > div a {
  text-decoration: none;
}
.mod_search > div a:hover .url {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}
.mod_search > div h2 {
  margin: 0;
}
.mod_search > div .url {
  margin: 0 0 0.125rem;
  color: rgba(68, 68, 68, 0.5019607843);
  word-break: break-all;
}
.mod_search > div .context {
  color: #444444;
}
.mod_search > div .highlight {
  padding: 0.0625rem 0.25rem;
  border-radius: 0.25rem;
  background-color: #f9c400;
}
@media only screen and (min-width: 576px) {
  .mod_search .widget-text {
    flex: initial;
  }
  .mod_search .widget-text input {
    width: 20rem;
  }
}

.teaser-list__list {
  display: flex;
  flex-wrap: wrap;
}
.teaser-list__item {
  width: 100%;
}
.teaser-list__item:not(:first-of-type) {
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid white;
  box-shadow: 0 -1px 0 #e0e0e0;
}
.teaser-list__image img {
  width: auto;
}
.teaser-list--type-standard .teaser-list__headline {
  margin: 0 0 0.375rem;
  font-family: "ITCFranklinGothicW02-Dm";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #5c5c5c;
}
.teaser-list--type-standard .teaser-list__text {
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
.teaser-list--type-standard .teaser-list__link {
  display: inline-block;
  margin-top: 0.875rem;
  padding: 0 1.875rem;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.6875rem;
  color: white;
  text-decoration: none;
  border-radius: 0.625rem;
  border: none;
  background: rgba(0, 0, 0, 0.2);
  transition: background 350ms ease;
}
.teaser-list--type-standard .teaser-list__link:hover {
  background: #dd7b07;
}
.teaser-list--type-bubble {
  position: relative;
  aspect-ratio: 1;
  background: url("../img/bubble.png") center no-repeat;
  background-size: contain;
}
.teaser-list--type-bubble .teaser-list__headline {
  position: absolute;
  bottom: 56%;
  margin: 0;
  width: 100%;
  font-family: "ITCFranklinGothicW02-Dm";
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
}
.teaser-list--type-bubble .teaser-list__text {
  position: absolute;
  top: 48%;
  padding: 0 2.5rem;
  width: 100%;
  font-size: 0.875rem;
  color: #444444;
  text-align: center;
}
.teaser-list--type-collection .teaser-list__form {
  display: flex;
  margin: 0.625rem 0 0;
}
.teaser-list--type-collection .teaser-list__input {
  flex: 1;
  padding: 0.4375rem 1rem 0.375rem;
  font-size: 0.875rem;
  border-radius: 30px;
  border: 1px solid #ededed;
  background: #ededed;
  transition: background 350ms ease;
}
.teaser-list--type-collection .teaser-list__input::-webkit-calendar-picker-indicator {
  display: none !important;
}
.teaser-list--type-collection .teaser-list__input:focus {
  background: white;
}
.teaser-list--type-collection .teaser-list__submit {
  margin-left: 0.625rem;
  width: 2.125rem;
  height: 2.125rem;
  font-family: "evbw";
  font-size: 1.3125rem;
  line-height: 1.3125rem;
  color: white;
  border-radius: 1.25rem;
  background: #bd010f;
}
@media only screen and (min-width: 576px) {
  .teaser-list__list {
    gap: 1.875rem;
  }
  .teaser-list__item {
    width: calc(50% - 15px);
  }
  .teaser-list__item:not(:first-of-type) {
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
  }
}
@media only screen and (min-width: 768px) {
  .teaser-list__item {
    width: calc(33.3333% - 20px);
  }
}
@media only screen and (min-width: 992px) {
  .teaser-list__list {
    flex-direction: column;
    gap: 0;
  }
  .teaser-list__item {
    width: 100%;
  }
  .teaser-list__item:not(:first-of-type) {
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    border-top: 1px solid white;
    box-shadow: 0 -1px 0 #e0e0e0;
  }
}