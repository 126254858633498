/* -------------------------------------------------------------
//  Accordion
// -----------------------------------------------------------*/
.Accordion {
  margin: 0;
  margin-bottom: $font-medium-line-height;
  // max-width: 872px;
  padding: 0;

  h3 {
    background-color: $color-white;
    // box-shadow: 0 0 20px rgb(0 0 0 / 5%);
    margin: 0;
    padding: 0;
  }
  &.focus {
    border-color: $color-black-40;
  }
}
.Accordion > * + * {
  border-top: 1px solid $color-black-40;
}
.Accordion-trigger {
  background: none;
  border-style: none;
  color: $color-black-90;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  line-height: to-rem($font-medium-line-height);
  margin: 0;
  padding: 1em 4em 1em 1.5em;
  position: relative;
  text-align: left;
  width: 100%;
  outline: none;

  &:focus,
  &:hover {
    background: #c5d3ec85;
    border-color: $color-black-40;
  }
}
.Accordion button::-moz-focus-inner {
  border: 0;
}
.Accordion-title {
  display: block;
  pointer-events: none;
  border: transparent 2px solid;
  border-radius: 5px;
  padding: 0.25em;
  outline: none;
}
.Accordion-trigger:focus .Accordion-title {
  /*border-color: hsl(216, 94%, 73%);*/
}
.Accordion-icon {
  height: to-rem($font-medium-line-height);
  pointer-events: none;
  position: absolute;
  right: to-rem($font-medium-font-size);
  top: to-rem($font-large-font-size);
  width: to-rem($font-medium-line-height);
  speak: none;
}
.Accordion-icon::after {
  content: "\e906";
  color: $color-primary;
  display: block;
  font-family: 'iconfont' !important;
  font-size: to-rem($font-large-font-size);
  font-weight: 300;
}
// .Accordion-icon::before {
//   transform: rotate(90deg);
// }
.Accordion-trigger:focus .Accordion-icon,
.Accordion-trigger:hover .Accordion-icon {
}
.Accordion-trigger[aria-expanded='true'] {
  border: none;
}
.Accordion-trigger[aria-expanded='true'] .Accordion-icon {
  speak: none;
}
.Accordion-trigger[aria-expanded='true'] .Accordion-icon::before {
  display: none;
}
.Accordion-trigger[aria-expanded='true'] .Accordion-icon::after {
  content: '\e909';
}
.Accordion-panel {
  background-color: $color-white;
  margin: 0;
  padding: 1em 1.5em;
}
.Accordion-panel[hidden] {
  display: none;
}
.Accordion-panel ul li {
  margin-bottom: 1em;
  padding-left: 30px;
}
