@mixin focus-ring {
  outline: 3px solid rgba($color-primary, 0.5);
}

.textfield {
  margin-bottom: to-rem($font-medium-line-height);

  &__label {
    display: block;
    font-family: $font-xsmall-font-family, Arial, sans-serif;
    font-size: to-rem($font-xsmall-font-size);
    line-height: to-rem($font-xsmall-line-height);
    margin-bottom: 0.25rem;
    pointer-events: none;
    //
  }

  &__input {
    background-color: $color-white;
    border: 1px solid $color-black-60;
    border-radius: 3px;
    color: $color-black-90;
    display: block;
    font-family: $font-medium-font-family, Arial, sans-serif;
    font-size: $font-medium-font-size;
    padding-left: to-rem($font-medium-font-size);
    height: 48px;
    width: 100%;
    //
    &:focus {
      @include focus-ring;
    }
  }
  // Label after input
  &__input:not(:placeholder-shown) + #{&}__label {
    //
  }
  
}

.textarea {
  margin-bottom: to-rem($font-medium-line-height);

  &__label {
    display: block;
    font-family: $font-xsmall-font-family, Arial, sans-serif;
    font-size: to-rem($font-xsmall-font-size);
    margin-bottom: 0.25rem;
    pointer-events: none;
    //
  }

  &__input {
    background-color: $color-white;
    border: 1px solid $color-black-60;
    border-radius: 3px;
    color: $color-black-90;
    display: block;
    font-family: $font-medium-font-family, Arial, sans-serif;
    padding-top: 0.75rem;
    padding-left: to-rem($font-medium-font-size);
    height: 48px;
    width: 100%;   
    //
    &:focus {
      @include focus-ring;
    }
  }
}



