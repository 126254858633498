/* -------------------------------------------------------------
//  Links
// -----------------------------------------------------------*/

a:not([class]) {
  //border-bottom: $color-primary 1px solid;
  color: #7b7b7b;
  text-decoration: underline;
  text-decoration-color: #7b7b7b;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;

  &:hover {
    //border-bottom: $color-primary-dark 1px solid;
    color: #444;
  }
}

.link {
  //border-bottom: $color-primary 1px solid;
  color: #444;
  text-decoration: none;

  &:hover {
    //border-bottom: $color-primary-dark 1px solid;
    //color: $color-primary-dark;
  }

  &--muted {
    border-bottom: $color-black-70 1px solid;
    color: $color-black-70;
    text-decoration: none;

    &:hover {
      border-bottom: $color-black-90 1px solid;
      color: $color-black-90;
    }
  }
}

