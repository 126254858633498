
#container {
  align-self: flex-start;
  margin-top: to-rem(60);
  margin-bottom: to-rem(40);

  @media only screen and (min-width: 576px) {
    margin-top: to-rem(60);
  }

  @media only screen and (min-width: 768px) {
    margin-top: to-rem(40);
  }
}

#main {
  position: relative;
  align-self: flex-start;

  @media only screen and (min-width: 992px) {
    &:has(~ #left) {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid white;
      }
    }

    &:has(~ #right) {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 0;
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid white;
      }
    }
  }
}
