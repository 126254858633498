/* -------------------------------------------------------------
//  Button
// -----------------------------------------------------------*/
.button {
  align-items: center;
  background-color: none;
  border: 1px solid $color-black-60;
  border-radius: 3px;
  color: $color-black-70;
  cursor: pointer;
  display: inline-flex;
  font-size: to-rem($font-button-font-size);
  font-weight: 700;
  line-height: to-rem($font-button-line-height);
  margin-bottom: to-rem($font-button-line-height);
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  // Flex Hack
  & > * {
    //margin-right: 0.5ch;
  }

  & > i {
    font-size: to-rem($font-button-font-size);
  }

  &:hover {
    background-color: none;
    border: 1px solid $color-black-90;
    color: $color-black-90;
  }

  &--primary {
    background-color: $color-primary;
    border: 1px solid $color-primary;
    color: $color-white !important;

    &:hover {
      background-color: $color-primary-dark;
      border: 1px solid $color-primary-dark;
    }
  }

  &--link {
    background-color: none;
    border: none;
    color: none;

    &:hover {
      background-color: none;
      border: $color-black-90 !important;
      color: $color-black-90;
    }
  }
}
