/* -------------------------------------------------------------
//  Toggle Select
// -----------------------------------------------------------*/
/*
.toggle-switch:has([type=checkbox]){
  height: 0;
  width: 0;
  visibility: hidden;
}
*/

.toggle-switch:has(label) {
  cursor: pointer;
  text-indent: -9999px;
  width: 4rem;
  height: 2rem;
  background: $color-black-30;
  display: block;
  border-radius: 100px;
  position: relative;
}


label:after {
  content: '';
  position: absolute;
  top: .25rem;
  left: .25rem;
  width: 1.5rem;
  height: 1.5rem;
  background: $color-white;
  border-radius: 100px;
  transition: 0.3s;
}


.toggle-switch:has(input:checked + label) {
  background: $color-primary;
}

input:checked + label:after {
  left: calc(100% - .25rem);
  transform: translateX(-100%);
}

label:active:after {
  width: 2rem;
}