/* -------------------------------------------------------------
//  Section
// -----------------------------------------------------------*/
.section {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    margin-bottom: to-rem($sizes-section);
  }

  &::after {
    margin-top: to-rem($sizes-section);
  }

  &--light {
    background-color: $color-black-10;
  }
  &--dark {
    background-color: $color-black-70;
    color: $color-white;
  }

  &.section-padding-none {
    border: none;

    &::before,
    &::after {
      display: none;
    }
  }

  &.section-padding-parking-sm {
    &::before {
      margin-bottom: clamp-calc-minmax(12px, 20px);
    }

    &::after {
      margin-top: clamp-calc-minmax(12px, 20px);
    }
  }

  &.section-padding-parking-md {
    &::before {
      margin-bottom: clamp-calc-minmax(24px, 40px);
    }

    &::after {
      margin-top: clamp-calc-minmax(24px, 40px);
    }
  }
}